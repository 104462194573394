import { Fetch } from '@types';
import { Action, FETCH_ACTIONS } from '@actions';
import { FetchActionGeneric } from '@actions';
import { FETCH } from '@constants';

const fetch = (state: Fetch = FETCH, action: Action<FetchActionGeneric>): Fetch => {
  switch (action.type) {
    case FETCH_ACTIONS.SET_FETCH: {
      return {
        ...state,
        isFetching: action.payload,
      };
    }
    default:
      return state;
  }
};

export default fetch;
