import React, { FC, FunctionComponentElement, ReactNode } from 'react';
import { ApiService } from '@api';
import { Props } from '@types';

const { Provider: ApiProvider, Consumer: ApiConsumer }: React.Context<ApiService> = React.createContext(new ApiService());

const withApiConsumer =
  () =>
  (Wrapped: FC<Props>) =>
  (props: Props): ReactNode =>
    <ApiConsumer>{(api: ApiService): FunctionComponentElement<Props> => <Wrapped {...props} api={api}></Wrapped>}</ApiConsumer>;

const api = new ApiService();

const withApiProvider =
  () =>
  (Wrapped: ReactNode): ReactNode => {
    return <ApiProvider value={api}>{Wrapped}</ApiProvider>;
  };

export { ApiConsumer, ApiProvider, withApiConsumer, withApiProvider };
