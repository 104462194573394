import { ProductListing, ShortcutType, TabType } from '@types';

const disable =
  (tab: TabType, type: ShortcutType) =>
  (row: ProductListing): boolean => {
    if (tab === 'all') {
      switch (type) {
        case 'settings': {
          return row.settings[1];
        }
        case 'colors': {
          return row.colors[1] || !row.mockup[0] || row.mockup[1] || !row.marketing[0] || row.marketing[1];
        }
        case 'mockup': {
          return row.mockup[1] || !row.settings[0] || row.settings[1];
        }
        case 'media': {
          return row.media[1] || !row.colors[0] || row.colors[1];
        }
        case 'marketing': {
          return row.marketing[1] || !row.settings[0] || row.settings[1];
        }
        case 'products': {
          return row.products[1] || !row.media[0] || row.media[1];
        }
        default: {
          return true;
        }
      }
    }

    if (tab === 'shelf' || tab === 'licence') {
      switch (type) {
        case 'settings': {
          return row.settings[1];
        }
        case 'specification': {
          return row.specification[1] || !row.settings[0] || row.settings[1];
        }
        case 'media': {
          return row.media[1] || !row.specification[0] || row.specification[1];
        }
        case 'marketing': {
          return row.marketing[1] || !row.specification[0] || row.specification[1];
        }
        case 'products': {
          return row.products[1] || !row.media[0] || row.media[1] || !row.marketing[0] || row.marketing[1];
        }
        default: {
          return true;
        }
      }
    }

    return true;
  };

export { disable };
