import React, { FC, useEffect, useState } from 'react';
import { Button, Checkbox, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { DownOutlined, UpOutlined } from '@ant-design/icons';

import { Card, CardProps, Filter, FilterOption } from '@types';
import { CARD, FILTER_STATE, TEXT_ACTION } from '@constants';
import { compose } from '@utils';
import { FilterCreators, FILTER_CREATORS } from '@actions';
import { CheckboxSelect } from '.';
import './CheckboxGroup.scss';

const CheckboxGroup: FC<CardProps & FilterCreators & Filter> = ({ type, cards, updateCard }) => {
  const [card, setCard] = useState<Card>(CARD);
  const [showAll, setShowAll] = useState<boolean>(true);
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    const index = cards.findIndex((card: Card) => card.type === type);

    if (index !== -1) {
      setCard(cards[index]);
      setSelected(cards[index].items.filter((item: FilterOption) => !!item[2]).map((item: FilterOption) => item[0]));
    }
  }, [type, cards]);

  useEffect(() => {
    updateCard(card);
    setSelected(card.items.filter((item: FilterOption) => !!item[2]).map((item: FilterOption) => item[0]));
  }, [card]);

  const toggle = (index: number) => {
    setCard(current => {
      const tag = current.items[index];
      tag[2] = !tag[2];
      return {
        ...current,
        items: [...current.items.slice(0, index), tag, ...current.items.slice(index + 1)],
      };
    });
  };

  const toggleShowAll = () => {
    setShowAll((current: boolean) => !current);
  };

  return (
    <section className='card-checkbox'>
      <div className='card-checkbox__header'>
        <Tooltip overlayClassName='card-checkbox__tooltip' visible={false} placement='topLeft' title={card.title}>
          <span className={`card-checkbox__title${card.items.length > (card.search ? 3 : 4) ? '--with-btn' : ''}`}>{card.title}</span>
        </Tooltip>
        {card.items.length > (card.search ? 3 : 4) && (
          <Button
            ghost
            className='card-checkbox__more'
            onClick={toggleShowAll}
            icon={showAll ? <DownOutlined style={{ marginTop: 4 }} /> : <UpOutlined />}
          >
            {card.items.length} {showAll ? TEXT_ACTION.MORE : TEXT_ACTION.LESS}
          </Button>
        )}
      </div>

      {card.search && (
        <div className='card-checkbox__search'>
          <CheckboxSelect showPlaceholder={false} selected={selected} visible={false} type={type} />
        </div>
      )}

      <div className={`card-checkbox__options${card.search ? '--search' : ''}`}>
        {card.items
          .map((item: FilterOption, index) => (
            <Checkbox key={index} className='card-checkbox__checkbox' checked={item[2]} onChange={() => toggle(index)}>
              <span className='card-checkbox__option-title tag-title'>{item[0]}</span>
              <span className='card-checkbox__option-title tag-index'> ({item[1]})</span>
            </Checkbox>
          ))
          .slice(0, showAll ? (card.search ? 3 : 4) : card.items.length)}
      </div>
    </section>
  );
};

export default compose(connect(FILTER_STATE, FILTER_CREATORS))(CheckboxGroup);
