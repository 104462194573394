import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Select, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import './Pagination.scss';

import { ApiProps, Pagination as State } from '@types';
import { compose, creators } from '@utils';
import { FILTER_STATE, PAGINATION_STATE } from '@constants';
import { CurrentPage, Page } from '@icons';
import { FilterCreators, FILTER_CREATORS, PaginationCreators, PAGINATION_CREATORS, TableCreators, TABLE_CREATORS } from '@actions';
import { withApiConsumer } from '@containers';

const Pagination: FC<State & PaginationCreators & PaginationCreators & ApiProps & TableCreators & FilterCreators> = ({
  current,
  total,
  pages: pageCount,
  size,
  setCurrent,
  api,
  setTab,
  setCards,
  setName,
  setRows,
  setSize,
  setTotal,
  tagsFilter,
}) => {
  const [pages, setPages] = useState<string[]>([]);
  useEffect(() => {
    setPages(creators.pages(size, pageCount, total));
  }, [pageCount, size, total]);

  const next = () => {
    setCurrent(current + 1);
    api.load(api.type, current + 1, undefined, tagsFilter).then(() => {
      // setCards(api.filter.cards);
      setRows(api.rows);
    });
  };

  const preview = () => {
    setCurrent(current - 1);
    api.load(api.type, current - 1, undefined, tagsFilter).then(() => {
      // setCards(api.filter.cards);
      setRows(api.rows);
    });
  };

  const select = (current: number | string) => {
    setCurrent(current as number);
    api.load(api.type, +current - 1, undefined, tagsFilter).then(() => {
      // setCards(api.filter.cards);
      setRows(api.rows);
    });
  };

  return (
    <section className='pagination'>
      <span className='pagination__title'>{creators.paginationTitle(current, size, total)}</span>

      <Button className='pagination__prev' disabled={current === 1} icon={<LeftOutlined />} onClick={preview} />
      <Select value={`Page ${current} of ${pageCount}`} onChange={select}>
        {pages.map((title: string, index: number) => (
          <Select.Option key={index} value={index + 1}>
            <div className='pagination__option'>
              <i className='pagination__icon'>{current === index + 1 ? <CurrentPage /> : <Page />}</i>
              <span>{title}</span>
            </div>
          </Select.Option>
        ))}
      </Select>
      <Button className='pagination__next' disabled={current === pageCount} icon={<RightOutlined />} onClick={next} />
    </section>
  );
};

export default compose(
  connect(PAGINATION_STATE, PAGINATION_CREATORS),
  connect(PAGINATION_STATE, TABLE_CREATORS),
  connect(FILTER_STATE, FILTER_CREATORS),
  withApiConsumer(),
)(Pagination);
