import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Title, Filter, Tabs } from '@templates';
import { compose } from '@utils';
import { withApiConsumer } from '@containers';
import { ApiProps, Card, CardType, Filter as FilterType, ProductListing, State } from '@types';
import { FETCH_STATE, FILTER_STATE, HEADER_FITERS, PAGINATION_STATE, SORT_STATE, TABLE_STATE } from '@constants';
import {
  FetchCreators,
  FETCH_CREATORS,
  FilterCreators,
  FILTER_CREATORS,
  PaginationCreators,
  PAGINATION_CREATORS,
  SortCreators,
  SORT_CREATORS,
  TableCreators,
  TABLE_CREATORS,
} from '@actions';

import './ProductListings.scss';

const ProductListings: FC<
  State & ApiProps & TableCreators & PaginationCreators & FilterType & FilterCreators & SortCreators & FetchCreators
> = ({
  api,
  setName,
  setCards,
  setRows,
  setPages,
  setCurrent,
  setSize,
  setTotal,
  setTab,
  search: { tags },
  setSearchOptions,
  setSearchFixedOptions,
  tab,
  cards,
  setFetch,
  isFetching,
  tagsFilter,
}) => {
  const [filters, setFilters] = useState<Card[]>([]);

  useEffect(() => {
    setFetch(true);
    api.load(tab, 1, undefined, tagsFilter).then(() => {
      setTab(api.type);
      setCards(api.filter.cards);
      setName(api.type);
      setRows(api.rows);
      setPages(api.pagination.pages);
      setCurrent(api.pagination.current);
      setSize(api.pagination.size);
      setTotal(api.pagination.total);
      setFetch(false);
    });
  }, [tab]);

  useEffect(() => {
    api.search(tags).then(([options, fixedOptions]: [any, ProductListing[]]) => {
      setSearchOptions(options);
      setSearchFixedOptions(fixedOptions);
    });
  }, [tags]);

  useEffect(() => {
    const filters: Card[] = [];

    HEADER_FITERS.forEach((type: CardType) => {
      const index = cards.findIndex((card: Card) => card.type === type);

      if (index !== -1) {
        filters.push(cards[index]);
      }
    });

    setFilters(filters);
  }, [cards]);

  return (
    <article className='listings__container'>
      <>
        <Title />
        <Filter.Filter filters={filters} />
        <Tabs isFetching={isFetching} />
      </>
    </article>
  );
};

export default compose(
  connect(TABLE_STATE, TABLE_CREATORS),
  connect(PAGINATION_STATE, PAGINATION_CREATORS),
  connect(FILTER_STATE, FILTER_CREATORS),
  connect(SORT_STATE, SORT_CREATORS),
  connect(FETCH_STATE, FETCH_CREATORS),
  withApiConsumer(),
)(ProductListings);
