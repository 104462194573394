import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Header } from '@templates';
import { RouterSwitch } from '@containers';
import { compose, hooks } from '@utils';
import { APP_ROUTER, AUTH_STATE, TABLE_STATE } from '@constants';
import { AuthCreators, AUTH_CREATORS, TableCreators, TABLE_CREATORS } from '@actions';

import './App.scss';
import { withAuthConsumer } from '@containers';
import { Auth, AuthProps } from '@types';
import fetchWithAuth from 'src/api/fetchWithAuth';
import axios from 'axios';

const App: FC<TableCreators & AuthProps & AuthCreators & Auth> = ({ setScroll, auth, setTokenAccess, setTokenRefresh }) => {
  const [scroll, setMainScroll] = useState<[x: number, y: number]>([0, 0]);

  const scrollDebounced = hooks.useDebounce(scroll, 200);

  setTokenAccess(auth.getTokenAccess());
  setTokenRefresh(auth.getTokenRefresh());

  useEffect(() => {
    setScroll(scrollDebounced);
  }, [scrollDebounced]);
  return (
    <>
      <Header />
      <main onScroll={e => setMainScroll([(e.target as any).scrollLeft, (e.target as any).scrollTop])} className='app__container'>
        <RouterSwitch routes={APP_ROUTER.routes} redirect={APP_ROUTER.redirect} />
      </main>
    </>
  );
};

export default compose(connect(TABLE_STATE, TABLE_CREATORS), connect(AUTH_STATE, AUTH_CREATORS), withAuthConsumer())(App);
