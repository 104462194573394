import React, { FC } from 'react';

import { ColumnTitleProps } from '@types';

import './Columns.scss';

const ColumnTitle: FC<ColumnTitleProps> = ({ Icon, Sort, Filter, title, className = 'column-title' }) => (
  <div className={className}>
    {!!Icon && <Icon />}
    <span>{title}</span>
    {!!Sort && <Sort />}
    {!!Filter && <Filter />}
  </div>
);

export default ColumnTitle;
