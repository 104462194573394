import React, { FC } from 'react';

import image from '@assets/png/ArtWork.png';
import './Cell.scss';

import { ImageProps } from '@types';

const Image: FC<ImageProps> = ({ src = image }) => {
  return <img className='cell__image' src={src} />;
};

export default Image;
