import { ProductListing, Table } from '@types';
import { TableActionGeneric, TABLE_ACTIONS, Action } from '@actions';
import { TABLE } from '@constants';

const table = (state: Table<ProductListing> = TABLE, action: Action<TableActionGeneric>): Table => {
  switch (action.type) {
    case TABLE_ACTIONS.SET_ROWS: {
      return {
        ...state,
        rows: action.payload,
      };
    }
    case TABLE_ACTIONS.SET_SELECTED: {
      return {
        ...state,
        selected: action.payload,
      };
    }
    case TABLE_ACTIONS.SET_NAME: {
      return {
        ...state,
        name: action.payload,
      };
    }
    case TABLE_ACTIONS.SET_SCROLL: {
      return {
        ...state,
        scroll: action.payload,
      };
    }
    default:
      return state;
  }
};

export default table;
