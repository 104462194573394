import { ActionsModule, Action } from '@actions';
import { Card, ProductListing, TabType } from '@types';
import { creators } from '@utils';
import { TableData } from 'src/types/filter.types';

export type FilterActionType =
  | '[filter] SET tab'
  | '[filter] SET cards'
  | '[filter] SET filters'
  | '[filter] UPDATE card'
  | '[filter] SET search tags'
  | '[filter] SET search options'
  | '[filter] SET search fixed options';

export type FilterActionGeneric = TabType & Card[] & Card & string[] & ProductListing[];

export interface FilterCreators {
  setTab: (payload: TabType) => Action<TabType>;
  setCards: (payload: Card[]) => Action<Card[]>;
  setTags: (payload: any) => Action<any>;
  updateCard: (payload: Card) => Action<Card>;
  setSearchTags: (payload: string[]) => Action<string[]>;
  setSearchOptions: (payload: TableData[]) => Action<TableData[]>;
  setSearchFixedOptions: (payload: ProductListing[]) => Action<ProductListing[]>;
}

export const ACTIONS: ActionsModule = {
  SET_TAB: '[filter] SET tab',
  SET_CARDS: '[filter] SET cards',
  SET_TAGS: '[filter] SET filters',
  UPDATE_CARD: '[filter] UPDATE card',
  SET_SEARCH_TAGS: '[filter] SET search tags',
  SET_SEARCH_OPTIONS: '[filter] SET search options',
  SET_SEARCH_FIXED_OPTIONS: '[filter] SET search fixed options',
};

export const CREATORS: FilterCreators = {
  setTab: creators.action<TabType>(ACTIONS.SET_TAB),
  setCards: creators.action<Card[]>(ACTIONS.SET_CARDS),
  setTags: creators.action<any>(ACTIONS.SET_TAGS),
  updateCard: creators.action<Card>(ACTIONS.UPDATE_CARD),
  setSearchTags: creators.action<string[]>(ACTIONS.SET_SEARCH_TAGS),
  setSearchOptions: creators.action<TableData[]>(ACTIONS.SET_SEARCH_OPTIONS),
  setSearchFixedOptions: creators.action<ProductListing[]>(ACTIONS.SET_SEARCH_FIXED_OPTIONS),
};
