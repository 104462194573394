import { FiltersData, ShortcutsData, TableData } from './../types/filter.types';
import { FILTER, FILTERS, FILTER_TITLE, PAGINATION, SEARCH, TEXT_TITLE, FILTERS_DATA } from '@constants';
import { Card, CardType, Filter, FilterOption, Pagination, ProductListing, Search, Shortcut, TabType } from '@types';
import axios from 'axios';
import { MainTags, Statuses } from 'src/types/filter.types';
import { ShortcutSettings } from 'src/types/table.types';
import parseGetData from '../utils/parseGetData';
import fetchWithAuth from './fetchWithAuth';

const instance = axios.create({
  baseURL: '/api/',
});
export default class ApiService {
  searchURL = 'productfactory/search';
  private _filteredColumns: CardType[] = FILTERS;
  private _filterOptions: Map<CardType, FilterOption[]> = new Map();
  private _filterSearch: Map<CardType, Search> = new Map();
  private _filter: Filter = FILTER;
  private _rows: ProductListing[] = [];
  private _type: TabType = 'all';
  private _pagination: Pagination = PAGINATION;
  private _filtersData: FiltersData = FILTERS_DATA;
  private _tableDataAutocomplite: TableData[] = [];
  private _tableDataSearch: TableData[] = [];
  private _tableDataAutocompliteFilter: TableData[] = [];

  inst(url: string, search_terms?: string[] | undefined, strFilters?: string, currentPage?: number | undefined, results_per_page?: number) {
    return () => {
      return instance.get(
        `${this.searchURL}${url}?${search_terms && `search_terms=${search_terms?.join(',')}`}&${strFilters}${
          currentPage && `page=${currentPage}&${results_per_page && `results_per_page=${results_per_page}`}`
        }`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('TOKEN_ACCESS')}` },
        },
      );
    };
  }

  get type(): TabType {
    return this._type;
  }

  set type(type: TabType) {
    this._type = type;
  }

  get tableDataAutocomplite(): TableData[] {
    return this._tableDataAutocomplite;
  }

  set tableDataAutocomplite(data: TableData[]) {
    this._tableDataAutocomplite = data;
  }
  get tableDataAutocompliteFilter(): TableData[] {
    return this._tableDataAutocompliteFilter;
  }

  set tableDataAutocompliteFilter(data: TableData[]) {
    this._tableDataAutocompliteFilter = data;
  }
  get tableDataSearch(): TableData[] {
    return this._tableDataSearch;
  }

  set tableDataSearch(data: TableData[]) {
    this._tableDataSearch = data;
  }

  get filter(): Filter {
    return this._filter;
  }

  set filter(filter: Filter) {
    this._filter = filter;
  }

  get filtersData(): FiltersData {
    return this._filtersData;
  }

  set filtersData(filter: FiltersData) {
    this._filtersData = filter;
  }

  get rows(): ProductListing[] {
    return this._rows;
  }

  set rows(rows: ProductListing[]) {
    this._rows = rows;
  }

  get pagination(): Pagination {
    return this._pagination;
  }

  set pagination(pagination: Pagination) {
    this._pagination = pagination;
  }

  public load(type: TabType, currentPage?: number, search_terms?: string[], tagsFilter?: any): Promise<void> {
    return new Promise(resolve => resolve(this.loadMocked(type, currentPage, search_terms, tagsFilter)));
  }

  public search(tags: string[]): Promise<[options: ProductListing[], fixedOptions: ProductListing[]]> {
    return new Promise(resolve => {
      if (tags.length === 1) {
        resolve([this.rows.slice(0, 2), this.rows.slice(this.rows.length - 2)]);
      }

      resolve([[], this.rows.slice(this.rows.length - 2)]);
    });
  }

  public searchAutocomplete(type: string, currentPage?: number, search_terms?: string[], tagsFilter?: any): Promise<void> {
    return new Promise(resolve => resolve(this.searchData(type, currentPage, search_terms, tagsFilter)));
  }

  private async searchData(type: string, currentPage?: number, search_terms?: string[], tagsFilter?: any) {
    await fetchWithAuth(this.inst('/autocomplete', search_terms, parseGetData(tagsFilter))).then(responce => {
      this.tableDataAutocompliteFilter = responce.data.products;
    });
  }

  private async loadMocked(type: TabType, currentPage?: number, search_terms?: string[], tagsFilter?: any) {
    if (currentPage) {
      this.pagination.current = currentPage;
    }

    await fetchWithAuth(this.inst('/filters'))
      .then(responce => {
        this.filtersData = responce.data;
      })
      .catch(e => {
        console.log('err');
      });

    await fetchWithAuth(this.inst('/autocomplete', search_terms, parseGetData(tagsFilter)))
      .then(responce => {
        this.tableDataAutocomplite = responce.data.products;
      })
      .catch(e => {
        console.log('err');
      });

    await fetchWithAuth(this.inst('', search_terms, parseGetData(tagsFilter), currentPage))
      .then(responce => {
        this.tableDataSearch = responce.data.products;
        this.pagination = {
          current: responce.data.pagination.page,
          total: responce.data.pagination.total_results,
          pages: Math.ceil(responce.data.pagination.total_results / responce.data.pagination.results_per_page),
          size: responce.data.pagination.results_per_page - 1,
        };
      })
      .catch(e => {
        console.log('err');
      });

    switch (type) {
      case 'all':
      case 'shelf':
      case 'licence': {
        this.filter = {
          ...FILTER,
          cards: this.initFilterCards(),
        };
        this.type = type;
        this.rows = this.getMockedRows(type);
        this.pagination = {
          current: this.pagination.current,
          total: this.pagination.total,
          pages: this.pagination.pages,
          size: this.pagination.size,
        };
        break;
      }
    }
  }

  private getMockedRows(type: TabType): ProductListing[] {
    const rows = [];

    for (let i = 0; i < this.tableDataSearch.length; i += 1) {
      rows.push({
        key: this.tableDataSearch[i].id.toString(),
        artWork: this.tableDataSearch[i].lowres_png,
        SKU: this.tableDataSearch[i].sku,
        ID: this.tableDataSearch[i].id.toString(),
        name: this.tableDataSearch[i].name,
        by: this.tableDataSearch[i].by_country,
        settings: [true, false, 'pending', this.tableDataSearch[i].statuses] as any,
        colors: [false, false, ''] as Shortcut,
        mockup: [false, false, ''] as Shortcut,
        media: [false, false, ''] as Shortcut,
        marketing: [false, false, '', this.tableDataSearch[i].marketing_missing_languages] as Shortcut,
        products: [false, false, 'archive'] as Shortcut,
        specification: [false, false, ''] as Shortcut,
        lastAction: [
          `${this.tableDataSearch[i].last_action ? this.tableDataSearch[i].last_action : 'none'}`,
          `${
            this.tableDataSearch[i].last_action_date ? this.tableDataSearch[i].last_action_date : ''
          }` /*new Date(new Date().getTime() - i * 100000000).toDateString()*/,
          `${this.tableDataSearch[i].last_action_user ? this.tableDataSearch[i].last_action_user : ''}`,
        ] as [action: string, date: string, user: string],
        actions: null,
      });
    }

    return rows;
  }

  private initFilterCards(): Card[] {
    this.setFilterOptions(this.tableDataAutocompliteFilter);
    this.setMockedFilterSearch();

    return this._filteredColumns
      .map(
        (type: CardType) =>
          ({
            title: FILTER_TITLE[type],
            type,
            items: type === 'by' ? this.setFiltersData(this.filtersData, 'by') : this._filterOptions.get(type),
          } as Card),
      )
      .map((card: Card) => {
        if (this._filterSearch.has(card.type)) {
          return {
            ...card,
            search: this._filterSearch.get(card.type),
          } as Card;
        }

        return card;
      });
  }

  private setFilterOptions(tableDataAutocompliteFilter: TableData[]): void {
    this.setMockedFilterOptions(tableDataAutocompliteFilter);
  }

  private setMockedFilterSearch(): void {
    this._filterSearch = new Map<CardType, Search>([
      [
        'tags',
        {
          ...SEARCH,
          placeholder: TEXT_TITLE.SEARCH_CARD_PLACEHOLDER,
          label: '',
        },
      ],
    ]);
  }

  private setSKUidNameBy(data: TableData[], type: string): any {
    const res = [] as any;
    let by = '';
    data.map((el: TableData) => {
      if (type === 'SKU') {
        res.push([el.sku, el.id, false]);
      }
      if (type === 'ID') {
        res.push([el.id.toString(), el.id, false]);
      }
      if (type === 'name') {
        res.push([el.name, el.id, false]);
      }
      if (type === 'by') {
        if (by !== el.by_country) {
          by = el.by_country;
          res.push([el.by_country, el.id, false]);
        } else return;
      }
    });
    return res;
  }

  private setFiltersData(data: FiltersData, type: string) {
    const res = [] as any;

    if (type === 'shortcuts') {
      data.shortcuts.map((el: ShortcutsData) => {
        res.push([el.name, el.id, false]);
      });
    }
    if (type === 'status') {
      data.statuses.map((el: Statuses) => {
        res.push([el.name, el.id, false]);
      });
    }
    if (type === 'tags') {
      data.main_tags.map((el: MainTags) => {
        res.push([el.tag, el.id, false]);
      });
    }
    if (type === 'by') {
      data.by_country.map((el: string, i: number) => {
        res.push([el, i, false]);
      });
    }
    return res;
  }

  private setMockedFilterOptions(autoFilter: TableData[]): void {
    this._filterOptions = new Map<CardType, FilterOption[]>([
      ['SKU', this.setSKUidNameBy(autoFilter.length > 0 ? autoFilter : this.tableDataSearch, 'SKU')],
      ['ID', this.setSKUidNameBy(this.tableDataSearch, 'ID')],
      ['name', this.setSKUidNameBy(this.tableDataSearch, 'name')],
      ['by', this.setSKUidNameBy(this.tableDataSearch, 'by')],
      [
        'settings',
        [
          ['error', 0, false, '#f8d3d3'],
          ['un-editable', 0, false, '#d2d2d1'],
          ['draft', 0, false, '#f6ddc5'],
          ['finished', 0, false, '#c5e0dc'],
        ],
      ],
      [
        'mockup',
        [
          ['error', 0, false, '#f8d3d3'],
          ['un-editable', 0, false, '#d2d2d1'],
          ['draft', 0, false, '#f6ddc5'],
          ['finished', 0, false, '#c5e0dc'],
        ],
      ],
      [
        'media',
        [
          ['error', 0, false, '#f8d3d3'],
          ['un-editable', 0, false, '#d2d2d1'],
          ['draft', 0, false, '#f6ddc5'],
          ['finished', 0, false, '#c5e0dc'],
        ],
      ],
      [
        'specification',
        [
          ['error', 0, false, '#f8d3d3'],
          ['un-editable', 0, false, '#d2d2d1'],
          ['draft', 0, false, '#f6ddc5'],
          ['finished', 0, false, '#c5e0dc'],
        ],
      ],
      [
        'colors',
        [
          ['error', 0, false, '#f8d3d3'],
          ['un-editable', 0, false, '#d2d2d1'],
          ['draft', 0, false, '#f6ddc5'],
          ['finished', 0, false, '#c5e0dc'],
        ],
      ],
      [
        'marketing',
        [
          ['error', 0, false, '#f8d3d3'],
          ['un-editable', 0, false, '#d2d2d1'],
          ['draft', 0, false, '#f6ddc5'],
          ['finished', 0, false, '#c5e0dc'],
        ],
      ],
      [
        'marketingLanguages',
        [
          ['en', 0, false, '#C5E0DC'],
          ['fr', 0, false],
          ['de', 0, false],
          ['zh', 0, false, '#F6DDC5'],
          ['ja', 0, false, '#DBD1E9'],
          ['ru', 0, false],
        ],
      ],
      [
        'products',
        [
          ['error', 0, false, '#f8d3d3'],
          ['un-editable', 0, false, '#d2d2d1'],
          ['draft', 0, false, '#f6ddc5'],
          ['finished', 0, false, '#c5e0dc'],
        ],
      ],
      ['status', this.setFiltersData(this.filtersData, 'status')],
      ['tags', this.setFiltersData(this.filtersData, 'tags')],
      ['shortcuts', this.setFiltersData(this.filtersData, 'shortcuts')],
    ]);
  }
}
