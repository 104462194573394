import React, { FC } from 'react';
import { Tabs as AntTabs, Tooltip as AntTooltip } from 'antd';
import { connect } from 'react-redux';

import { FILTER_STATE, TABS, TEXT_TITLE } from '@constants';
import { Filter as FilterType, Tab } from '@types';
import { Info } from '@icons';
import { FilterCreators, FILTER_CREATORS } from '@actions';
import { compose, convert } from '@utils';
import { Filter, Table } from '@templates';

import './Tabs.scss';

const Tooltip = (
  <div className='tooltip'>
    <span className='tooltip--error'>{TEXT_TITLE.ERROR}</span>
    <span className='tooltip--un-editable'>{TEXT_TITLE.UN_EDITABLE}</span>
    <span className='tooltip--draft'>{TEXT_TITLE.DRAFT}</span>
    <span className='tooltip--finished'>{TEXT_TITLE.FINISHED}</span>
  </div>
);

const Tabs: FC<FilterCreators & FilterType> = ({ tab = 'all', setTab, isFetching }) => {
  const onChange = (active: string) => {
    setTab(convert.stringToTabType(active));
  };

  return (
    <section className='tabs__container'>
      <div className='color-meaning'>
        <span className='color-meaning__text'>{TEXT_TITLE.COLOR_MEANING}</span>
        <AntTooltip placement='leftTop' overlayStyle={{ minWidth: 350 }} title={Tooltip} color='white'>
          <Info />
        </AntTooltip>
      </div>

      <AntTabs onChange={onChange} defaultActiveKey={tab} tabPosition='top' className='tabs'>
        {TABS.map(({ title, type }: Tab) => (
          <AntTabs.TabPane className='tab' tab={title} key={type} disabled={!isFetching ? false : true}></AntTabs.TabPane>
        ))}
      </AntTabs>
      <div className='tabs'>
        <Filter.Active />
        <Table.Table />
      </div>
    </section>
  );
};

export default compose(connect(FILTER_STATE, FILTER_CREATORS))(Tabs);
