import { Auth } from '@types';
import { Action, AUTH_ACTIONS } from '@actions';
import { AUTH } from '@constants';
import { AuthActionGeneric } from '@actions';

const auth = (state: Auth = AUTH, action: Action<AuthActionGeneric>): Auth => {
  switch (action.type) {
    case AUTH_ACTIONS.SET_TOKEN_ACCESS: {
      return {
        ...state,
        AccessToken: action.payload,
      };
    }
    case AUTH_ACTIONS.SET_TOKEN_REFRESH: {
      return {
        ...state,
        RefreshToken: action.payload,
      };
    }
    case AUTH_ACTIONS.SET_USER_NAME: {
      return {
        ...state,
        user: {
          ...state.user,
          name: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default auth;
