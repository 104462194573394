import { FilterCreators } from '@actions';
import { TEXT_ACTION, TEXT_TITLE } from '@constants';
import { Empty as Icon } from '@icons';
import { Button } from 'antd';
import React, { FC } from 'react';

const Empty: FC<FilterCreators & any> = ({ setSearchTags, setTags, setCards, api, setRows, setFetch }) => (
  <section className='table--empty'>
    <h1>{TEXT_TITLE.NO_RESULTS_FOUND}</h1>
    <h3>{TEXT_TITLE.TRY_AGAIN}</h3>
    <Icon />
    <Button
      className='clear'
      onClick={() => {
        setFetch(true);
        api.load('all').then(() => {
          setTags({});
          setSearchTags([]);
          setCards(api.filter.cards);
          setRows(api.rows);
          setFetch(false);
        });
        // window.location.reload();
      }}
    >
      {TEXT_ACTION.CLEAR_SEARCH_FILTERS}
    </Button>
  </section>
);

export default Empty;
