import React, { FC } from 'react';

import './Cell.scss';

import { LastActionProps } from '@types';

const LastAction: FC<LastActionProps> = ({ action, date }) => {
  return (
    <div className='cell__last-action'>
      <span className='cell__last-action--title'>
        &quot;<b>{action}</b>&quot;
      </span>
      <span className='cell__last-action--date'>{date}</span>
    </div>
  );
};

export default LastAction;
