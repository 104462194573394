import { Action, FilterActionGeneric, FILTER_ACTIONS } from '@actions';
import { FILTER } from '@constants';
import { Card, Filter, TabType } from '@types';

const filter = (state: Filter = FILTER, action: Action<FilterActionGeneric>): Filter => {
  switch (action.type) {
    case FILTER_ACTIONS.SET_TAB: {
      return {
        ...state,
        tab: action.payload as TabType,
      };
    }
    case FILTER_ACTIONS.SET_CARDS: {
      return {
        ...state,
        cards: action.payload as Card[],
      };
    }
    case FILTER_ACTIONS.SET_TAGS: {
      return {
        ...state,
        tagsFilter: action.payload,
      };
    }
    case FILTER_ACTIONS.UPDATE_CARD: {
      if (state.cards.length) {
        const index = state.cards.findIndex((card: Card) => card.type === action.payload.type);
        if (index !== -1) {
          return {
            ...state,
            cards: [...state.cards.slice(0, index), action.payload, ...state.cards.slice(index + 1)],
          };
        }
      }

      return state;
    }
    case FILTER_ACTIONS.SET_SEARCH_TAGS: {
      return {
        ...state,
        search: {
          ...state.search,
          tags: action.payload,
        },
      };
    }
    case FILTER_ACTIONS.SET_SEARCH_OPTIONS: {
      return {
        ...state,
        search: {
          ...state.search,
          options: action.payload,
        },
      };
    }
    case FILTER_ACTIONS.SET_SEARCH_FIXED_OPTIONS: {
      return {
        ...state,
        search: {
          ...state.search,
          fixedOptions: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default filter;
