import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Divider, Select, Tag } from 'antd';

import { SEARCH, FILTER_STATE, TEXT_TITLE, TABLE_STATE, PAGINATION_STATE, SORT_STATE, FETCH_STATE } from '@constants';
import { ApiProps, Card, Filter as Props, FilterProps, ProductListing, State, Filter as FilterType } from '@types';
import { compose, creators } from '@utils';
import { Search } from '@icons';
import { Filter as Cards } from '@templates';

import image from '@assets/png/ArtWork.png';

import './Filter.scss';
import { PlusOutlined } from '@ant-design/icons';
import {
  Action,
  FetchCreators,
  FETCH_CREATORS,
  FilterCreators,
  FILTER_CREATORS,
  PaginationCreators,
  PAGINATION_CREATORS,
  SortCreators,
  SORT_CREATORS,
  TableCreators,
  TABLE_CREATORS,
} from '@actions';
import { withApiConsumer } from '@containers';
import { TableData } from 'src/types/filter.types';

const Filter: FC<
  Props & FilterProps & State & ApiProps & TableCreators & PaginationCreators & FilterType & FilterCreators & SortCreators & FetchCreators
> = ({ search = SEARCH, filters = [], setSearchTags, setFetch, api, setCards, setRows, setSearchOptions, tagsFilter, isFetching }) => {
  const [open, setOpen] = useState<boolean>(false);

  if (!filters.length && !search) {
    return <></>;
  }

  const onSelect = (tag: string) => {
    if (tag) {
      setOpen(true);
      setSearchTags([...search.tags, tag]);
      setFetch(true);
      api.load(api.type, 1, [...search.tags, tag], tagsFilter).then(() => {
        setSearchOptions(api.tableDataAutocomplite);
        setRows(api.rows);
        setFetch(false);
      });
    }
  };

  const onDropdownVisibleChange = (status: boolean) => {
    if (!status) {
      return setOpen(false);
    }

    if (!!status && !!search.tags.length) {
      return setOpen(true);
    }
  };
  return (
    <section className='filter__container'>
      <div className='filter__cards'>
        {filters.map((filter: Card) => (
          <Cards.CheckboxGroup key={filter.type} type={filter.type} />
        ))}
      </div>
      <div className='filter__search'>
        <span className='filter__search-label'>{search.label}</span>
        <Select
          dropdownClassName='filter__search-dropdown'
          // dropdownStyle={{ height: calcDropdownHeight(search.options.length, search.fixedOptions.length) }}
          className={
            open ? (search.tags.length > 0 && search.options.length === 0 ? 'filter__search--error' : 'filter__search--active') : ''
          }
          mode='tags'
          getPopupContainer={trigger => trigger.parentNode}
          dropdownAlign={{
            overflow: { adjustY: 0 },
          }}
          open={open}
          tagRender={e => tags(e)(search.tags, setSearchTags)}
          value={search.tags}
          placeholder={<Placeholder placeholder={search.placeholder} focus={open} />}
          // maxTagCount='responsive'
          onSelect={onSelect}
          onDropdownVisibleChange={onDropdownVisibleChange}
        >
          {!isFetching ? (
            <Select.Option value='' className='filter__search-options--container'>
              {search.options.length === 0 ? (
                <div className='filter__search--no-results'>
                  <span>{search.options.length}</span>
                  <span>{TEXT_TITLE.NO_RESULTS}</span>
                </div>
              ) : (
                <>
                  <div className='filter__search--results-count'>
                    <span>{search.options.length}</span>
                    <span>{TEXT_TITLE.RESULT}</span>
                  </div>
                  <div className='filter__search-options'>
                    {search.options.map((option: any) => (
                      <div key={option.id} className='filter__search-option'>
                        <img className='filter__search-option--image' src={option.lowres_png} />
                        <div className='filter__search-option--info'>
                          <span>{option.name}</span>
                          <div>
                            <span>{option.sku}</span>
                            <span>{option.id}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}

              {search.fixedOptions.length > 0 && (
                <>
                  <div className='filter__search--resent'>{TEXT_TITLE.RESENT}</div>
                  <div className='filter__search-options'>
                    {search.fixedOptions.map((option: any) => (
                      <div key={option.ID} className='filter__search-option'>
                        <img className='filter__search-option--image' src={option.artWork} />
                        <div className='filter__search-option--info'>
                          <span>{option.name}</span>
                          <div>
                            <span>{option.SKU}</span>
                            <span>{option.ID}</span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </Select.Option>
          ) : (
            <div className='fetching'>Loading</div>
          )}
        </Select>
        <Search className='filter__search-icon' />
      </div>
    </section>
  );
};

interface IPlaceholder {
  focus: boolean;
  placeholder: string;
}

const Placeholder: FC<IPlaceholder> = ({ focus, placeholder }) => {
  if (focus) {
    return (
      <div className='filter__search-placeholder'>
        <Tag className='filter__search-placeholder-tag'>
          {TEXT_TITLE.SEARCH_CARD_PLACEHOLDER}
          <PlusOutlined />
        </Tag>
      </div>
    );
  }

  return <span>{placeholder}</span>;
};

const calcDropdownHeight = (optionCount: number, fixedOptionCount: number): number => {
  let optionsHeight = 42;
  let fixedOptionsHeight = 0;

  if (fixedOptionCount > 0) {
    if (fixedOptionCount <= 6) {
      fixedOptionsHeight = fixedOptionCount * 92 + 50;
    } else {
      fixedOptionsHeight = 6 * 92 + 50;
    }
  }

  if (optionCount > 0) {
    if (optionCount <= 6) {
      optionsHeight = optionCount * 92 + 50;
    } else {
      optionsHeight = 6 * 92 + 50;
    }
  }

  return optionsHeight + fixedOptionsHeight;
};

const tags = (props: any) => {
  const { label, closable } = props;

  return (tags: string[], setTags: (payload: string[]) => Action<string[]>) => {
    const onClose = (label: string) => {
      const index = tags.findIndex((tag: string) => tag === label);

      if (index !== -1) {
        setTags([...tags.slice(0, index), ...tags.slice(index + 1)]);
      }
    };

    const onPreventMouseDown = (event: any) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={creators.color('', false)}
        className='card-select__selected'
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={() => onClose(label)}
      >
        {label}
      </Tag>
    );
  };
};

export default compose(
  connect(TABLE_STATE, TABLE_CREATORS),
  connect(PAGINATION_STATE, PAGINATION_CREATORS),
  connect(FILTER_STATE, FILTER_CREATORS),
  connect(SORT_STATE, SORT_CREATORS),
  connect(FETCH_STATE, FETCH_CREATORS),
  withApiConsumer(),
)(Filter);
