import { TableCreators, TABLE_CREATORS } from '@actions';
import { TABLE_ACTIONS, TABLE_STATE, TEXT_ACTION, TEXT_TITLE } from '@constants';
import { ProductListing, Table } from '@types';
import { compose } from '@utils';
import { Checkbox, Select } from 'antd';
import React, { FC, Key, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import './Summary.scss';

const { Option } = Select;

const Summary: FC<Table & TableCreators> = ({ selected, rows, setSelected }) => {
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);

  const selectAll = () => {
    select();
    setAllChecked(true);
  };

  const select = () => {
    setChecked(true);
    setSelected(rows.map((row: ProductListing) => row.key));
  };

  const clear = () => {
    setChecked(false);
    setAllChecked(false);
    setSelected([]);
  };

  useEffect(() => {
    if (selected.length) {
      if (allRowsSelected(selected, rows)) {
        setChecked(true);
        setIndeterminate(false);
        return;
      } else {
        setIndeterminate(true);
        return;
      }
    }

    setChecked(false);
    setIndeterminate(false);
    return;
  }, [selected, rows]);

  return (
    <section className='global-actions'>
      <div>
        <Checkbox checked={checked} onClick={clear} indeterminate={indeterminate}>
          {selected.length} {TEXT_TITLE.SELECTED}
        </Checkbox>
        <Select value={TEXT_TITLE.ACTIONS}>
          {TABLE_ACTIONS.map((title: string, index: number) => (
            <Option key={index} value={title}>
              <span className='action__name'>{title}</span>
            </Option>
          ))}
        </Select>
      </div>
      <div className='select-checkboxes'>
        <Checkbox onClick={checked ? clear : select} checked={checked}>
          {checked ? TEXT_ACTION.THIS_PAGE_SELECTED : TEXT_ACTION.SELECT_THIS_PAGE}
        </Checkbox>
        <Checkbox checked={allChecked} onClick={allChecked ? clear : selectAll}>
          {allChecked ? TEXT_ACTION.ALL_PAGES_SELECTED : TEXT_ACTION.SELECT_ALL_PAGES}
        </Checkbox>
      </div>
    </section>
  );
};

const allRowsSelected = (selected: Key[], rows: ProductListing[]): boolean =>
  rows.every((row: ProductListing) => selected.findIndex((key: Key) => row.key === key) !== -1);

export default compose(connect(TABLE_STATE, TABLE_CREATORS))(Summary);
