import { Action, SortActionGeneric, SORT_ACTIONS } from '@actions';
import { SORT } from '@constants';
import { Sort } from '@types';

const sort = (state: Sort = SORT, action: Action<SortActionGeneric>): Sort => {
  switch (action.type) {
    case SORT_ACTIONS.SET_LAST_ACTION_ORDER: {
      return {
        ...state,
        lastAction: action.payload,
      };
    }
    default:
      return state;
  }
};

export default sort;
