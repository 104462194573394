import { TOKEN_ACCESS, TOKEN_REFRESH } from '@constants';
import { resourceLimits } from 'worker_threads';
import axios from 'axios';
import { Auth } from '@types';
import { Tokens } from 'src/types/auth.types';
// import fetchWithAuth from 'src/utils/hooks/fetchWithAuth';

export default class AuthService {
  setToken(tokens: Tokens) {
    localStorage.setItem(TOKEN_ACCESS, tokens.AccessToken);
    localStorage.setItem(TOKEN_REFRESH, tokens.RefreshToken);
  }

  getTokenAccess(): string {
    return localStorage.getItem(TOKEN_ACCESS) || '';
  }
  getTokenRefresh(): string {
    return localStorage.getItem(TOKEN_REFRESH) || '';
  }

  getToken() {
    // return fetchWithAuth('/token/refresh/', {});
  }

  login(username: string, password: string): Promise<Tokens> {
    // console.log('login: ', username, password);
    return axios
      .post(`/api/token/`, { username, password })
      .then(resolve => {
        if (resolve.data.access) {
          localStorage.setItem('TIME', `${Date.now() + 4 * 60 * 1000}`);
          this.setToken({
            AccessToken: resolve.data.access,
            RefreshToken: resolve.data.refresh,
          });

          return {
            AccessToken: resolve.data.access,
            RefreshToken: resolve.data.refresh,
          };
        } else {
          return {
            AccessToken: 'none',
            RefreshToken: 'none',
          };
        }
      })
      .catch(e => ({
        AccessToken: 'none',
        RefreshToken: 'none',
      }));
  }

  logout(): void {
    localStorage.removeItem(TOKEN_ACCESS);
  }
}
