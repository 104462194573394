import { Pagination } from '@types';
import { PAGINATION_ACTIONS, Action } from '@actions';
import { PAGINATION } from '@constants';

const pagination = (state: Pagination = PAGINATION, action: Action<number>): Pagination => {
  switch (action.type) {
    case PAGINATION_ACTIONS.SET_CURRENT: {
      return {
        ...state,
        current: action.payload,
      };
    }
    case PAGINATION_ACTIONS.SET_TOTAL: {
      return {
        ...state,
        total: action.payload,
      };
    }
    case PAGINATION_ACTIONS.SET_PAGES: {
      return {
        ...state,
        pages: action.payload,
      };
    }
    case PAGINATION_ACTIONS.SET_SIZE: {
      return {
        ...state,
        size: action.payload,
      };
    }
    default:
      return state;
  }
};

export default pagination;
