import { ProductListing } from '@types';
export interface Tab {
  title: string;
  type: TabType;
}

export interface MainTags {
  id: number;
  tag: string;
}

export interface Statuses {
  id: number;
  code: string;
  name: string;
}

export interface StatusesProduct {
  colours: string;
  marketing: string;
  media: string;
  mockup: string;
  products: string;
  settings: string;
}
export interface TableData {
  by_country: string;
  id: number;
  last_action: string | null;
  last_action_date: string | null;
  last_action_user: string | null;
  licence_status: string | null;
  lowres_png: string;
  marketing_missing_languages: string[];
  name: string;
  sku: string;
  statuses: StatusesProduct[];
}

export interface ParametersShortcuts {
  by_countries: string[];
  main_tags: number[];
  statuses: number[];
}
export interface ShortcutsData {
  id: number;
  name: string;
  parameters: ParametersShortcuts[];
}

export interface FiltersData {
  by_country: string[];
  main_tags: MainTags[];
  shortcuts: ShortcutsData[];
  statuses: Statuses[];
}

export interface Search {
  model: string;
  tags: string[];
  label: string;
  placeholder: string;
  options: ProductListing[] | TableData[];
  fixedOptions: ProductListing[] | TableData[];
}

export interface Card {
  title: string;
  type: CardType;
  items: FilterOption[];
  search?: Search;
}

export interface Filter {
  cards: Card[];
  tab: TabType;
  search: Search;
  tagsFilter: any;
  isFetching?: boolean;
}

export type CardType = keyof ProductListing | 'shortcuts' | 'tags' | 'status' | 'marketingLanguages';
export type FilterOption = [title: string, resultsCount: number, selected: boolean, color?: string];
export type TabType = 'all' | 'licence' | 'shelf';

export enum Color {
  tagsLightGray = '#e5e5e3',
  tagsDarkGray = '#d2d2d1',
  tagsBrown = '#dbd4d1',
  tagsRed = '#f8d3d3',
  tagsOrange = '#f6ddc5',
  tagsYellow = '#f8ecc3',
  tagsPink = '#edcce0',
  tagsPurple = '#dbd1e9',
  tagsBlue = '#d0e3ec',
  tagsGreen = '#c5e0dc',
  mainGray = '#33353a',
}

export enum ColorActive {
  tagsLightGray = '#b8b8b7',
  tagsDarkGray = '#8f8f8f',
  tagsBrown = '#e39566',
  tagsRed = '#f96464',
  tagsOrange = '#f8ac63',
  tagsYellow = '#f9e495',
  tagsPink = '#ed8ec8',
  tagsPurple = '#b38ce9',
  tagsBlue = '#60c4f0',
  tagsGreen = '#5ae0cc',
  mainGray = '#E39566',
}
