import React, { FC, useEffect, useState } from 'react';
import { Divider, Table as AntTable } from 'antd';

import './Table.scss';
import Pagination from './Pagination';
import Empty from './Empty';
import Summary from './Summary';
import { ALL_PRODUCT_LISTINGS_COLUMNS, LICENCE_PRODUCT_LISTINGS_COLUMNS, SHELF_PRODUCT_LISTINGS_COLUMNS } from './Columns';

import { ApiProps, Filter, ProductListing, State, Table as Type } from '@types';
import { compose, creators } from '@utils';
import { connect } from 'react-redux';
import { FETCH_STATE, FILTER_STATE, TABLE_STATE } from '@constants';
import { FetchCreators, FETCH_CREATORS, FilterCreators, FILTER_CREATORS, TableCreators, TABLE_CREATORS } from '@actions';
import { ColumnType } from 'antd/lib/table';
import { withApiConsumer } from '@containers';

const Table: FC<State & Type & TableCreators & Filter & FetchCreators & FilterCreators & ApiProps & TableCreators> = ({
  tab = 'all',
  rows = [],
  selected = [],
  setSelected,
  scroll,
  isFetching,
  setTags,
  setSearchTags,
  setCards,
  setRows,
  setFetch,
  api,
}) => {
  const [summary, setSummary] = useState<boolean>(false);
  const [columns, setColumns] = useState<ColumnType<ProductListing>[]>(ALL_PRODUCT_LISTINGS_COLUMNS);

  useEffect(() => {
    switch (tab) {
      case 'all': {
        setColumns(ALL_PRODUCT_LISTINGS_COLUMNS);
        break;
      }
      case 'licence': {
        setColumns(LICENCE_PRODUCT_LISTINGS_COLUMNS);
        break;
      }
      case 'shelf': {
        setColumns(SHELF_PRODUCT_LISTINGS_COLUMNS);
        break;
      }
      default: {
        setColumns(ALL_PRODUCT_LISTINGS_COLUMNS);
        break;
      }
    }
  }, [tab]);

  useEffect(() => {
    if (selected.length && scroll[1] > 100) {
      return setSummary(true);
    }

    return setSummary(false);
  }, [selected, scroll]);

  return (
    <>
      {!isFetching ? (
        <>
          {rows.length === 0 ? (
            <Empty setTags={setTags} setSearchTags={setSearchTags} setCards={setCards} api={api} setRows={setRows} setFetch={setFetch} />
          ) : (
            <>
              {summary && <Summary />}
              <AntTable
                className='table'
                showHeader={true}
                rowSelection={creators.selection(selected, setSelected)}
                dataSource={rows}
                columns={columns}
                size='small'
                tableLayout='fixed'
                pagination={false}
              />
              <Pagination />
            </>
          )}
        </>
      ) : (
        <div className='fetching'>Loading</div>
      )}
    </>
  );
};

export default compose(
  connect(TABLE_STATE, TABLE_CREATORS),
  connect(FILTER_STATE, FILTER_CREATORS),
  connect(FETCH_STATE, FETCH_CREATORS),
  connect(TABLE_STATE, TABLE_CREATORS),
  withApiConsumer(),
)(Table);
