import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducers';

const store = createStore(reducer, applyMiddleware(thunk));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
/* @ts-ignore */
window.store = store;

export default store;
