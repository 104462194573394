import React from 'react';
import { AUTH, FILTER, PAGINATION, SORT, TABLE, TEXT_ACTION, TEXT_TITLE } from '@constants';
import { PlusOutlined } from '@ant-design/icons';
import { Auth, ErrorBoundaryState, Fetch, Filter, Pagination, Sort, State, Table, Title, TitleAction } from '@types';
import { FETCH } from './fetch.const';

export const TITLE_ACTION: TitleAction = {
  title: '',
  className: '',
  click: () => null,
};

export const TITLE_ACTIONS: TitleAction[] = [
  {
    title: TEXT_ACTION.MULTIUPLOAD,
    className: 'action--default',
    click: () => console.log("'Multiupload' clicked"),
  },
  {
    title: TEXT_ACTION.NEW_SHELF_ITEM,
    className: 'action--secodary',
    click: () => console.log("'New shelf item' clicked"),
  },
  {
    title: TEXT_ACTION.NEW_PRODUCT,
    className: 'action--primary',
    click: () => console.log("'New product' clicked"),
    icon: <PlusOutlined />,
  },
];

export const TITLE: Title = {
  title: TEXT_TITLE.PRODUCT_LISTINGS,
  actions: TITLE_ACTIONS,
};

export const STATE: State = {
  filter: FILTER,
  sort: SORT,
  table: TABLE,
  pagination: PAGINATION,
  auth: AUTH,
  fetch: FETCH,
};

export const FILTER_STATE = ({ filter }: State = STATE): Filter => {
  return { ...filter };
};

export const SORT_STATE = ({ sort }: State = STATE): Sort => {
  return { ...sort };
};

export const TABLE_STATE = ({ table }: State = STATE): Table => {
  return { ...table };
};

export const PAGINATION_STATE = ({ pagination }: State = STATE): Pagination => {
  return { ...pagination };
};

export const AUTH_STATE = ({ auth }: State = STATE): Auth => {
  return { ...auth };
};
export const FETCH_STATE = ({ fetch }: State = STATE): Fetch => {
  return { ...fetch };
};

export const ERROR_BOUNDARY_STATE: ErrorBoundaryState = {
  hasError: false,
};
