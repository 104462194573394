import { CardType } from '@types';

export const ACTION: Record<string, string> = {
  MULTIUPLOAD: 'multiupload',
  NEW_SHELF_ITEM: 'New shelf item',
  NEW_PRODUCT: 'New product',
  ARCHIVE: 'Archive',
  DOWNLOAD_PNG: 'Download PNG',
  APPROVE_LICENSE: 'Approve License',
  CREAT_MULTILIST: 'Create Multi-list',
  SEND_LICENSE_REQUEST: 'Send License Request',
  CLEAR_SEARCH_FILTERS: 'clear search & filters',
  SELECT_THIS_PAGE: 'select this page',
  SELECT_ALL_PAGES: 'select all pages',
  THIS_PAGE_SELECTED: 'this page selected',
  ALL_PAGES_SELECTED: 'all pages selected',
  SELECT_ALL: 'select all',
  CLEAR_ALL: 'clear all',
  UPLOAD: 'Upload',
  MORE: 'more',
  LOGIN: 'login',
  LESS: 'less',
};

export const TITLE: Record<string, string> = {
  PRODUCT_LISTINGS: 'Product listings',
  SEARCH: 'Search',
  SEARCH_PLACEHOLDER: 'Separate by comma search for SKU, ID, Titles...',
  ALL_ARTWORKS: 'All Artworks',
  LICENCE: 'Licence',
  SHELF: 'Shelf',
  COLOR_MEANING: 'color meaning',
  ERROR: 'error',
  UN_EDITABLE: 'Un-editable',
  DRAFT: 'Draft',
  FINISHED: 'Finished',
  NO_RESULTS_FOUND: 'No results found',
  TRY_AGAIN: 'Try again with fewer keywords or other conditions',
  SELECTED: 'selected',
  ACTIONS: 'actions',
  SEARCH_CARD_PLACEHOLDER: 'Add a filter',
  ACTIVE_FILTERS: 'active filters',
  RESULT: 'Result',
  NO_RESULTS: 'Search results - try to narrow your search',
  RESENT: 'recent artworks',
  LOGIN_INFO: 'log in with your details',
  USER_NAME: 'username',
  USER_NAME_ERROR: 'error text',
  USER_NAME_PLACEHOLDER: 'enter your username',
  PASSWORD_PLACEHOLDER: 'enter your password',
  PASSWORD_ERROR: 'password must be 6 letters',
  PASSWORD: 'password',
};

export const DESCRIPTION: Record<string, string> = {};

export const TABLE_ACTIONS: string[] = [
  ACTION.ARCHIVE,
  ACTION.DOWNLOAD_PNG,
  ACTION.APPROVE_LICENSE,
  ACTION.CREAT_MULTILIST,
  ACTION.SEND_LICENSE_REQUEST,
  ACTION.UPLOAD,
];

export const TABLE_TITLE: Record<string, string> = {
  artWork: 'art work',
  SKU: 'SKU',
  ID: 'ID',
  name: 'name',
  by: 'by',
  settings: 'settings',
  colors: 'colors',
  mockup: 'mockup',
  media: 'media',
  marketing: 'marketing',
  products: 'products',
  specification: 'specification',
  lastAction: 'last action',
  actions: '',
};

export const FILTER_TITLE: Record<CardType, string> = {
  shortcuts: 'shortcuts',
  tags: 'main tags',
  status: 'Status',
  SKU: 'search for SKU',
  ID: 'search for ID',
  name: 'search for name',
  by: 'License status',
  settings: 'Stage status',
  colors: 'Stage status',
  mockup: 'Stage status',
  media: 'Stage status',
  marketing: 'Stage status',
  products: 'Stage status',
  specification: 'Stage status',
  marketingLanguages: 'Languages',
  actions: '',
  artWork: '',
  lastAction: '',
  key: '',
};
