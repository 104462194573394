import axios from 'axios';

async function fetchWithAuth(cb: any) {
  if (!localStorage.getItem('TOKEN_ACCESS')) {
    return window.open('/login', '_self');
  }

  if (localStorage.getItem('TOKEN_ACCESS')) {
    const time = localStorage.getItem('TIME');
    if (time) {
      if (Date.now() >= +time) {
        try {
          await axios.post('/api/token/refresh/', { refresh: localStorage.getItem('TOKEN_REFRESH') }).then(responce => {
            localStorage.setItem('TOKEN_ACCESS', responce.data.access);
            localStorage.setItem('TIME', `${Date.now() + 4 * 60 * 1000}`);
          });
        } catch (e) {
          return window.open('/login', '_self');
        }
      }
    }
  }

  return cb();
}

export default fetchWithAuth;
