import React, { FC } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';

import { SortCreators, SORT_CREATORS } from '@actions';
import { SORT_STATE } from '@constants';
import { Sort as SortIcon } from '@icons';
import { Sort as SortState } from '@types';
import { compose } from '@utils';

import './Sort.scss';

const LastAction: FC<SortState & SortCreators> = ({ lastAction, setLastActionOrder }) => {
  const onClick = () => {
    switch (lastAction) {
      case 'asc': {
        return setLastActionOrder('desc');
      }
      case 'desc': {
        return setLastActionOrder('asc');
      }
      default: {
        return;
      }
    }
  };
  return <Button shape='circle' className='sort' onClick={onClick} icon={<SortIcon className={lastAction} />} />;
};

const Sort = {
  LastAction: compose(connect(SORT_STATE, SORT_CREATORS))(LastAction),
};

export default Sort;
