import { Factory, Login, ProductListings } from '@containers';
import { RouterProps } from '@types';

export const APP_ROUTER: RouterProps = {
  routes: [
    {
      path: '/login',
      protected: false,
      template: Login,
      redirect: '/login',
    },
    {
      path: '/factory',
      protected: false,
      redirect: '/login',
      template: Factory,
    },
  ],
  redirect: {
    authorized: '/factory',
    unauthorized: '/login',
  },
};

export const FACTORY_ROUTER: RouterProps = {
  routes: [
    {
      path: '/factory/product-listings',
      protected: true,
      redirect: '/login',
      template: ProductListings,
    },
  ],
  redirect: {
    authorized: '/factory/product-listings',
    unauthorized: '/login',
  },
};
