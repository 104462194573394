import React, { Component } from 'react';

import { Error } from '@templates';
import { Props, ErrorBoundaryState } from '@types';
import { ERROR_BOUNDARY_STATE } from '@constants';

export default class ErrorBoundary extends Component<Props, ErrorBoundaryState> {
  public state: ErrorBoundaryState = ERROR_BOUNDARY_STATE;

  public componentDidCatch(): void {
    this.setState({ hasError: true });
  }

  public render(): React.ReactNode {
    if (this.state.hasError) {
      return <Error />;
    }

    return this.props.children;
  }
}
