import React, { FC, useState } from 'react';
import { Dropdown } from 'antd';

import { Filter } from '@icons';
import { ColumnDropdownProps } from '@types';

const ColumnDropdown: FC<ColumnDropdownProps> = ({ Card, type }) => {
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <Dropdown
      onVisibleChange={setVisible}
      destroyPopupOnHide
      className={`filter__dropdown ${visible ? 'filter__dropdown--visible' : ''}`}
      overlay={<Card type={type} />}
      trigger={['click']}
    >
      <Filter />
    </Dropdown>
  );
};

export default ColumnDropdown;
