import { Dropdown, Menu } from 'antd';
import React, { FC } from 'react';
import { SettingsProps } from 'src/types/props.types';

import './Cell.scss';

const Settings: FC<SettingsProps> = ({ Icon, statuses }) => {
  const menu = (
    <Menu className='settings_items_menu'>
      License status
      <Menu.Item key='1'>
        <a href=''>Rejected</a>
      </Menu.Item>
      <Menu.Item key='2'>
        <a href=''>Pending</a>
      </Menu.Item>
      <Menu.Item key='3'>
        <a href=''>Aproved</a>
      </Menu.Item>
    </Menu>
  );
  console.log(statuses);
  return (
    <Dropdown overlay={menu} placement='bottomRight' trigger={['click']}>
      <div className='settings_select'>
        {!!Icon && <Icon />}
        <div>
          <button className={`${statuses?.settings}`}>{statuses?.settings}</button>
        </div>
      </div>
    </Dropdown>
  );
};

export default Settings;
