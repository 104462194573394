const parseGetData = (tagsFilter: any) => {
  const resFilter = [
    { tagName: 'Status', values: [] },
    { tagName: 'shortcuts', values: [] },
    { tagName: 'License status', values: [] },
    { tagName: 'main tags', values: [] },
  ] as any;
  for (const key in tagsFilter) {
    resFilter.map((el: any, i: number) => {
      if (tagsFilter[key] === el.tagName) {
        resFilter[i].values.push(key);
      }
    });
  }
  const renameFilters = resFilter.map((el: any) => {
    if (el.tagName === 'Status') {
      return { tagName: 'statuses', values: [...el.values] };
    }
    if (el.tagName === 'shortcuts') {
      return el;
    }
    if (el.tagName === 'License status') {
      return { tagName: 'by_countries', values: [...el.values] };
    }
    if (el.tagName === 'main tags') {
      return { tagName: 'main_tags', values: [...el.values] };
    }
  });

  let strFilters = '';
  for (let i = 0; i < renameFilters.length; i++) {
    if (renameFilters[i].values.length > 0) {
      strFilters += `${renameFilters[i].tagName}=${renameFilters[i].values.join(',')}&`;
    }
  }

  return strFilters;
};

export default parseGetData;
