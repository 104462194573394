import React, { FC } from 'react';
import { Alert } from 'antd';

import { ErrorProps } from '@types';

import './Error.scss';

const Error: FC<ErrorProps> = ({ message = 'Unregistered error' }) => (
  <div className='error'>
    <Alert message={message} description='Something wrong...' banner type='error' />
  </div>
);

export default Error;
