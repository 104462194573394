import { AUTH_STATE, TEXT_ACTION, TEXT_TITLE } from '@constants';
import React, { FC, MouseEvent, useRef, useState } from 'react';
import { Form, Input, Button, Divider } from 'antd';

import { Logo } from '@icons';

import './Login.scss';
import { compose } from '@utils';
import { withAuthConsumer } from '@containers';
import { Auth, AuthProps } from '@types';
import { connect } from 'react-redux';
import { AuthCreators, AUTH_CREATORS } from '@actions';
import { Tokens } from 'src/types/auth.types';

const Login: FC<AuthCreators & AuthProps> = ({ auth, setTokenAccess, setTokenRefresh, setUserName }) => {
  const [name, setName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [nameFocused, setNameFocused] = useState<boolean>(false);
  const [isValidUserName, setIsValidUserName] = useState<boolean | null>(null);
  const [isValidPassword, setIsValidPassword] = useState<boolean | null>(null);
  const [errLogin, setErrLogin] = useState('');

  const login = (event: MouseEvent) => {
    event.preventDefault();

    auth.login(name, password).then((auth: Tokens) => {
      if (auth.AccessToken !== 'none') {
        setTokenAccess(auth.AccessToken);
        setTokenRefresh(auth.RefreshToken);
        setUserName(name);
        window.open('/factory/product-listings', '_self');
      } else {
        setErrLogin('Unautorized!');
      }
    });
  };

  const onNameChange = (name: string) => {
    setName(name);
    setIsValidUserName(!!name.trim());
  };

  const onPasswordChange = (password: string) => {
    setPassword(password);
    setIsValidPassword(password.length > 6);
  };

  const onPasswordBlur = () => {
    setIsValidPassword(password.length > 6);
  };

  const onNameBlur = () => {
    setNameFocused(false);
    setIsValidUserName(!!name.trim());
  };

  return (
    <section className='login__container'>
      {errLogin && <div className='err_login'>{errLogin}</div>}
      <div className='login__logo'>
        <Logo className='login__logo--icon' />
      </div>
      <span className='login__info'>{TEXT_TITLE.LOGIN_INFO}</span>

      <Form layout='vertical'>
        <Form.Item
          name='username'
          label={TEXT_TITLE.USER_NAME}
          className={`
            ${isValidUserName !== null && !isValidUserName ? 'login__input--with-error' : ''}
            ${nameFocused ? 'login__input--focused' : ''}
          `}
        >
          {isValidUserName !== null && !isValidUserName && <span className='login__error'>{TEXT_TITLE.USER_NAME_ERROR}</span>}
          <Input
            value={name}
            placeholder={TEXT_TITLE.USER_NAME_PLACEHOLDER}
            onFocus={() => setNameFocused(true)}
            onBlur={() => onNameBlur()}
            onChange={e => onNameChange(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name='password'
          label={TEXT_TITLE.PASSWORD}
          className={isValidPassword !== null && !isValidPassword ? 'login__input--with-error' : ''}
        >
          {isValidPassword !== null && !isValidPassword && <span className='login__error'>{TEXT_TITLE.PASSWORD_ERROR}</span>}
          <Input.Password
            value={password}
            placeholder={TEXT_TITLE.PASSWORD_PLACEHOLDER}
            onBlur={() => onPasswordBlur()}
            onChange={e => onPasswordChange(e.target.value)}
          />
        </Form.Item>
        <Form.Item className='login__btn'>
          <Button disabled={!isValidUserName || !isValidPassword} type='primary' onClick={login}>
            {TEXT_ACTION.LOGIN}
          </Button>
        </Form.Item>
      </Form>
    </section>
  );
};

export default compose(connect(AUTH_STATE, AUTH_CREATORS), withAuthConsumer())(Login);
