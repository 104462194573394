import React, { FC, FunctionComponentElement, ReactNode } from 'react';
import { AuthService } from '@api';
import { Props } from '@types';

const { Provider: AuthProvider, Consumer: AuthConsumer }: React.Context<AuthService> = React.createContext(new AuthService());

const withAuthConsumer =
  () =>
  (Wrapped: FC<Props>) =>
  (props: Props): ReactNode =>
    <AuthConsumer>{(auth: AuthService): FunctionComponentElement<Props> => <Wrapped {...props} auth={auth}></Wrapped>}</AuthConsumer>;

const auth = new AuthService();

const withAuthProvider =
  () =>
  (Wrapped: ReactNode): ReactNode => {
    return <AuthProvider value={auth}>{Wrapped}</AuthProvider>;
  };

export { AuthProvider, AuthConsumer, withAuthConsumer, withAuthProvider };
