import React, { FC, useEffect, useState } from 'react';
import { PageHeader, Breadcrumb, Dropdown, Button, Menu } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

import { Logo } from '@icons';

import './Header.scss';

const Header: FC = () => {
  const [location, setLocation] = useState<string[]>([]);

  const Logout = () => {
    localStorage.removeItem('TOKEN_ACCESS');
    localStorage.removeItem('TIME');
    window.location.reload();
  };

  useEffect(() => {
    setLocation(window.location.pathname.split('/').filter(Boolean));
  }, [window.location.pathname]);

  const menu = (
    <Menu>
      <Menu.Item key='1'>
        <a onClick={Logout}>Logout</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <header className='header__container'>
      <Dropdown overlay={menu} placement='bottomLeft'>
        <MenuOutlined className='header__menu' />
      </Dropdown>

      <div className='header__logo'>
        <Logo className='header__logo--icon' />
      </div>
      <PageHeader
        title={
          <Breadcrumb>
            {location.map((part: string) => (
              <Breadcrumb.Item key={part}>
                <a href=''>{part}</a>
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        }
      ></PageHeader>
    </header>
  );
};

export default Header;
