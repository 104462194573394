import { ProductListing } from '@types';
import { ActionsModule, Action } from '@actions';
import { creators } from '@utils';
import { Key } from 'react';
import { ColumnType } from 'antd/lib/table';

export type TableActionType =
  | '[table] SET rows'
  | '[table] SET columns'
  | '[table] SET name'
  | '[table] SET selected'
  | '[table] SET scroll (to toggle summary)';

export type TableActionGeneric = string & [x: number, y: number] & ProductListing[] & Key[] & ColumnType<ProductListing>[];

export interface TableCreators {
  setName: (payload: string) => Action<string>;
  setRows: (payload: ProductListing[]) => Action<ProductListing[]>;
  setSelected: (payload: Key[]) => Action<Key[]>;
  setColumns: (payload: ColumnType<ProductListing>[]) => Action<ColumnType<ProductListing>[]>;
  setScroll: (payload: [x: number, y: number]) => Action<[x: number, y: number]>;
}

export const ACTIONS: ActionsModule = {
  SET_NAME: '[table] SET name',
  SET_ROWS: '[table] SET rows',
  SET_SELECTED: '[table] SET selected',
  SET_COLUMNS: '[table] SET columns',
  SET_SCROLL: '[table] SET scroll (to toggle summary)',
};

export const CREATORS: TableCreators = {
  setName: creators.action<string>(ACTIONS.SET_NAME),
  setRows: creators.action<ProductListing[]>(ACTIONS.SET_ROWS),
  setSelected: creators.action<Key[]>(ACTIONS.SET_SELECTED),
  setColumns: creators.action<ColumnType<ProductListing>[]>(ACTIONS.SET_COLUMNS),
  setScroll: creators.action<[x: number, y: number]>(ACTIONS.SET_SCROLL),
};
