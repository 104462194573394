import { State } from '@types';
import { STATE } from '@constants';
import { ActionGeneric, Action } from '@actions';

import filter from './filter.reducer';
import table from './table.reducer';
import pagination from './pagination.reducer';
import sort from './sort.reducer';
import auth from './auth.reducer';
import fetch from './fetch.reducer';

const reducer = (state: State = STATE, action: Action<ActionGeneric>): State => {
  return {
    filter: filter(state.filter, action),
    sort: sort(state.sort, action),
    table: table(state.table, action),
    pagination: pagination(state.pagination, action),
    auth: auth(state.auth, action),
    fetch: fetch(state.fetch, action),
  };
};

export default reducer;
