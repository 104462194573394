import React from 'react';
import { ColumnType } from 'antd/lib/table';

import { ProductListing, Shortcut } from '@types';
import { Cell, Filter, Table } from '@templates';
import { By, Clock, Hashtag, Image, Shortcuts } from '@icons';
import { creators, shortcuts } from '@utils';
import { TABLE_TITLE } from '@constants';
import { RouterSwitch } from '@containers';
import { ShortcutSettings } from 'src/types/table.types';
import { Divider } from 'antd';

export const COLUMNS_WITHOUT_SHORTCUT: ColumnType<ProductListing>[] = [
  creators.column(
    'artWork',
    () => <Table.ColumnTitle Icon={Image} title={TABLE_TITLE['artWork']} />,
    (src: string) => <Cell.Image src={src} />,
    90,
    'column column--image',
  ),
  creators.column(
    'SKU',
    () => (
      <Table.ColumnTitle
        Icon={Hashtag}
        Filter={() => <Filter.ColumnDropdown type='SKU' Card={Filter.CheckboxSelect} />}
        title={TABLE_TITLE['SKU']}
      />
    ),
    undefined,
    90,
    'column--left',
  ),
  creators.column(
    'ID',
    () => (
      <Table.ColumnTitle
        Icon={Hashtag}
        Filter={() => <Filter.ColumnDropdown type='ID' Card={Filter.CheckboxSelect} />}
        title={TABLE_TITLE['ID']}
      />
    ),
    undefined,
    70,
    'column--left',
  ),
  creators.column(
    'name',
    () => (
      <Table.ColumnTitle
        Icon={Shortcuts.Marketing}
        Filter={() => <Filter.ColumnDropdown type='name' Card={Filter.CheckboxSelect} />}
        title={TABLE_TITLE['name']}
      />
    ),
    undefined,
    260,
    'column--left',
  ),
  creators.column(
    'by',
    () => (
      <Table.ColumnTitle Icon={By} Filter={() => <Filter.ColumnDropdown type='by' Card={Filter.TagGroup} />} title={TABLE_TITLE['by']} />
    ),
    undefined,
    80,
    'column--left',
  ),
  creators.column(
    'lastAction',
    () => <Table.ColumnTitle Sort={Table.Sort.LastAction} Icon={Clock} title={TABLE_TITLE['lastAction']} />,
    ([action, date]: [action: string, date: string]) => <Cell.LastAction action={action} date={date} />,
    190,
    'column--left',
  ),
  creators.column(
    'actions',
    () => <Table.ColumnTitle title={''} />,
    () => <Cell.Actions />,
    32,
  ),
];

export const ALL_SHORTCUTS: ColumnType<ProductListing>[] = [
  creators.column(
    'settings',
    () => (
      <Table.ColumnTitle
        Filter={() => <Filter.ColumnDropdown type='settings' Card={Filter.TagGroup} />}
        Icon={Shortcuts.Settings}
        title={TABLE_TITLE['settings']}
      />
    ),
    (settings: ShortcutSettings, row: ProductListing) => (
      <Cell.Checkbox
        disabled={shortcuts.disable('licence', 'settings')(row)}
        Icon={Shortcuts.Settings}
        checked={true}
        // status={settings[2]}
        type='settings'
        statuses={settings[3]}
        sku={row.SKU}
      />
    ),
    135,
  ),
  creators.column(
    'mockup',
    () => <Table.ColumnTitle Filter={() => <Filter.ColumnDropdown type='mockup' Card={Filter.TagGroup} />} title={TABLE_TITLE['mockup']} />,
    (cell: Shortcut, row: ProductListing) => (
      <Cell.Checkbox Icon={Shortcuts.Mockup} disabled={shortcuts.disable('all', 'mockup')(row)} checked={cell[0]} />
    ),
    135,
  ),
  creators.column(
    'colors',
    () => <Table.ColumnTitle Filter={() => <Filter.ColumnDropdown type='colors' Card={Filter.TagGroup} />} title={TABLE_TITLE['colors']} />,
    (cell: Shortcut, row: ProductListing) => (
      <Cell.Checkbox Icon={Shortcuts.Colors} disabled={shortcuts.disable('all', 'colors')(row)} checked={cell[0]} />
    ),
    135,
  ),
  creators.column(
    'media',
    () => <Table.ColumnTitle Filter={() => <Filter.ColumnDropdown type='media' Card={Filter.TagGroup} />} title={TABLE_TITLE['media']} />,
    (cell: Shortcut, row: ProductListing) => (
      <Cell.Checkbox Icon={Shortcuts.Media} disabled={shortcuts.disable('all', 'media')(row)} checked={cell[0]} />
    ),
    135,
  ),
  creators.column(
    'marketing',
    () => (
      <Table.ColumnTitle
        Filter={() => (
          <Filter.ColumnDropdown
            type='marketing'
            Card={({ type }) => (
              <>
                <Filter.TagGroup type={type} />
                <Filter.TagGroupLanguage type='marketingLanguages' />
              </>
            )}
          />
        )}
        title={TABLE_TITLE['marketing']}
      />
    ),
    (cell: Shortcut, row: ProductListing) => (
      <Cell.Checkbox Icon={Shortcuts.Marketing} disabled={shortcuts.disable('all', 'marketing')(row)} checked={false} flags={cell[3]} />
    ),
    200,
  ),
  creators.column(
    'products',
    () => (
      <Table.ColumnTitle
        Filter={() => <Filter.ColumnDropdown type='products' Card={Filter.TagGroup} />}
        Icon={Clock}
        title={TABLE_TITLE['products']}
      />
    ),
    (cell: Shortcut, row: ProductListing) => (
      <Cell.Checkbox Icon={Shortcuts.Products} disabled={shortcuts.disable('all', 'products')(row)} status={cell[2]} checked={cell[0]} />
    ),
    135,
  ),
];

export const LICENCE_SHORTCUTS: ColumnType<ProductListing>[] = [
  creators.column(
    'settings',
    () => (
      <Table.ColumnTitle
        Filter={() => <Filter.ColumnDropdown type='settings' Card={Filter.TagGroup} />}
        Icon={Shortcuts.Settings}
        title={TABLE_TITLE['settings']}
      />
    ),
    (settings: ShortcutSettings, row: ProductListing) => (
      <Cell.Checkbox
        disabled={shortcuts.disable('licence', 'settings')(row)}
        Icon={Shortcuts.Settings}
        // checked={cell[0]}
        // status={cell[2]}
        type='settings'
        statuses={settings[3]}
      />
    ),
    135,
  ),
  creators.column(
    'specification',
    () => (
      <Table.ColumnTitle
        Filter={() => <Filter.ColumnDropdown type='specification' Card={Filter.TagGroup} />}
        title={TABLE_TITLE['specification']}
      />
    ),
    (cell: Shortcut, row: ProductListing) => (
      <Cell.Checkbox Icon={Shortcuts.Specifications} disabled={shortcuts.disable('licence', 'specification')(row)} checked={cell[0]} />
    ),
    135,
  ),
  creators.column(
    'media',
    () => <Table.ColumnTitle Filter={() => <Filter.ColumnDropdown type='media' Card={Filter.TagGroup} />} title={TABLE_TITLE['media']} />,
    (cell: Shortcut, row: ProductListing) => (
      <Cell.Checkbox Icon={Shortcuts.Media} disabled={shortcuts.disable('licence', 'media')(row)} checked={cell[0]} />
    ),
    135,
  ),
  creators.column(
    'marketing',
    () => (
      <Table.ColumnTitle
        Filter={() => <Filter.ColumnDropdown type='marketing' Card={Filter.TagGroup} />}
        title={TABLE_TITLE['marketing']}
      />
    ),
    (cell: Shortcut, row: ProductListing) => (
      <Cell.Checkbox Icon={Shortcuts.Marketing} disabled={shortcuts.disable('licence', 'marketing')(row)} checked={false} flags={cell[3]} />
    ),
    200,
  ),
  creators.column(
    'products',
    () => (
      <Table.ColumnTitle
        Filter={() => <Filter.ColumnDropdown type='products' Card={Filter.TagGroup} />}
        Icon={Clock}
        title={TABLE_TITLE['products']}
      />
    ),
    (cell: Shortcut, row: ProductListing) => (
      <Cell.Checkbox
        Icon={Shortcuts.Products}
        disabled={shortcuts.disable('licence', 'products')(row)}
        status={cell[2]}
        checked={cell[0]}
      />
    ),
    135,
  ),
];

export const SHELF_SHORTCUTS: ColumnType<ProductListing>[] = [
  creators.column(
    'settings',
    () => (
      <Table.ColumnTitle
        Filter={() => <Filter.ColumnDropdown type='settings' Card={Filter.TagGroup} />}
        Icon={Shortcuts.Settings}
        title={TABLE_TITLE['settings']}
      />
    ),
    (settings: ShortcutSettings, row: ProductListing) => (
      <Cell.Checkbox
        disabled={shortcuts.disable('licence', 'settings')(row)}
        Icon={Shortcuts.Settings}
        // checked={cell[0]}
        // status={cell[2]}
        type='settings'
        statuses={settings[3]}
      />
    ),
    135,
  ),
  creators.column(
    'specification',
    () => (
      <Table.ColumnTitle
        Filter={() => <Filter.ColumnDropdown type='specification' Card={Filter.TagGroup} />}
        title={TABLE_TITLE['specification']}
      />
    ),
    (cell: Shortcut, row: ProductListing) => (
      <Cell.Checkbox Icon={Shortcuts.Specifications} disabled={shortcuts.disable('shelf', 'specification')(row)} checked={cell[0]} />
    ),
    135,
  ),
  creators.column(
    'media',
    () => <Table.ColumnTitle Filter={() => <Filter.ColumnDropdown Card={Filter.TagGroup} type='media' />} title={TABLE_TITLE['media']} />,
    (cell: Shortcut, row: ProductListing) => (
      <Cell.Checkbox Icon={Shortcuts.Media} disabled={shortcuts.disable('shelf', 'media')(row)} checked={cell[0]} />
    ),
    135,
  ),
  creators.column(
    'marketing',
    () => (
      <Table.ColumnTitle
        Filter={() => <Filter.ColumnDropdown type='marketing' Card={Filter.TagGroup} />}
        title={TABLE_TITLE['marketing']}
      />
    ),
    (cell: Shortcut, row: ProductListing) => (
      <Cell.Checkbox Icon={Shortcuts.Marketing} disabled={shortcuts.disable('shelf', 'marketing')(row)} checked={false} flags={cell[3]} />
    ),
    200,
  ),
  creators.column(
    'products',
    () => (
      <Table.ColumnTitle
        Filter={() => <Filter.ColumnDropdown type='products' Card={Filter.TagGroup} />}
        Icon={Clock}
        title={TABLE_TITLE['products']}
      />
    ),
    (cell: Shortcut, row: ProductListing) => (
      <Cell.Checkbox Icon={Shortcuts.Products} disabled={shortcuts.disable('shelf', 'products')(row)} status={cell[2]} checked={cell[0]} />
    ),
    135,
  ),
];

export const ALL_PRODUCT_LISTINGS_COLUMNS: ColumnType<ProductListing>[] = [
  ...COLUMNS_WITHOUT_SHORTCUT.slice(0, 5),
  ...ALL_SHORTCUTS,
  ...COLUMNS_WITHOUT_SHORTCUT.slice(5),
];

export const LICENCE_PRODUCT_LISTINGS_COLUMNS: ColumnType<ProductListing>[] = [
  ...COLUMNS_WITHOUT_SHORTCUT.slice(0, 5),
  ...LICENCE_SHORTCUTS,
  ...COLUMNS_WITHOUT_SHORTCUT.slice(5),
];

export const SHELF_PRODUCT_LISTINGS_COLUMNS: ColumnType<ProductListing>[] = [
  ...COLUMNS_WITHOUT_SHORTCUT.slice(0, 5),
  ...SHELF_SHORTCUTS,
  ...COLUMNS_WITHOUT_SHORTCUT.slice(5),
];
