import { AUTH_CREATORS } from '@actions';
import { AUTH_STATE } from '@constants';
import { Auth, RouterProps, Route as IRoute } from '@types';
import { compose } from '@utils';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

export const RouterSwitch: FC<RouterProps & Auth> = ({ routes, redirect, AccessToken }) => (
  <Switch>
    {routes.length &&
      routes.map((route: IRoute) => {
        if (route.protected && !AccessToken) {
          return <Redirect to={route.redirect} />;
        }

        return (
          <Route key={route.path} path={route.path}>
            <route.template />
          </Route>
        );
      })}
    <Route path='*'>{AccessToken ? <Redirect to={redirect.authorized} /> : <Redirect to={redirect.unauthorized} />}</Route>
  </Switch>
);

export default compose(connect(AUTH_STATE, AUTH_CREATORS))(RouterSwitch);
