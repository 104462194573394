import {
  FetchCreators,
  FETCH_CREATORS,
  FilterActionType,
  FilterCreators,
  FILTER_CREATORS,
  PAGINATION_CREATORS,
  TableCreators,
  TABLE_CREATORS,
} from '@actions';
import { FETCH_STATE, FILTER_STATE, SORT_STATE, TABLE_STATE, TEXT_TITLE } from '@constants';
import { ApiConsumer, withApiConsumer } from '@containers';
import { Filter, Card, FilterOption, CardType, State, ApiProps } from '@types';
import { compose, creators } from '@utils';
import { Tag } from 'antd';
import React, { FC, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';

import './Active.scss';

const Active: FC<Filter & State & ApiProps & TableCreators & FilterCreators & FetchCreators> = ({
  cards,
  updateCard,
  setTags,
  api,
  setRows,
  setFetch,
  search,
}) => {
  const onClose = (cardType: CardType, itemTitle: string) => {
    const index = cards.findIndex((card: Card) => card.type === cardType);
    if (index !== -1) {
      const itemIndex = cards[index].items.findIndex((item: FilterOption) => item[0] === itemTitle);
      if (itemIndex !== -1) {
        const tag = cards[index].items[itemIndex];
        tag[2] = false;
        updateCard({
          ...cards[index],
          items: [...cards[index].items.slice(0, itemIndex), tag, ...cards[index].items.slice(itemIndex + 1)],
        });
      }
    }
  };

  useEffect(() => {
    const tags = {} as any;
    cards
      .filter(card => !!card.items.filter(item => !!item[2]).length)
      .map((card: Card) => {
        card.items
          .filter((item: FilterOption) => !!item[2])
          .map((item: FilterOption) => {
            if (card.title === 'License status') {
              tags[item[0]] = card.title;
              return;
            }
            tags[item[1]] = card.title;
          });
      });
    setTags(tags);
    setFetch(true);
    api.load(api.type, 1, search.tags, tags).then(() => {
      setRows(api.rows);
      setFetch(false);
    });
  }, [cards]);

  return (
    <div className='active-filter'>
      {cards
        .filter(card => !!card.items.filter(item => !!item[2]).length)
        .map((card: Card, index: number) => (
          <Fragment key={card.title}>
            {index === 0 && <span className='active-filter__title'>{TEXT_TITLE.ACTIVE_FILTERS}</span>}
            <Tag className='active-filter__item-tag'>
              <span className='active-filter__item-title'>{card.title}&nbsp;</span>
              {card.items
                .filter((item: FilterOption) => !!item[2])
                .map((item: FilterOption, i, list) => (
                  <Fragment key={item[0]}>
                    <Tag
                      className='active-filter__option-tag'
                      color={creators.color('', false)}
                      onClose={() => onClose(card.type, item[0])}
                      closable
                    >
                      <span className='active-filter__option-title'>{item[0]}</span>
                    </Tag>
                    {i !== list.length - 1 && <>/&nbsp;&nbsp;</>}
                  </Fragment>
                ))}
            </Tag>
          </Fragment>
        ))}
    </div>
  );
};

export default compose(
  connect(TABLE_STATE, TABLE_CREATORS),
  connect(FILTER_STATE, FILTER_CREATORS),
  connect(FETCH_STATE, FETCH_CREATORS),
  withApiConsumer(),
)(Active);
