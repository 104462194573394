import { ActionsModule, Action, ActionCreators } from '@actions';
import { creators } from '@utils';

export type FetchActionType = '[load] SET fetch';

export type FetchActionGeneric = boolean;

export interface FetchCreators extends ActionCreators<boolean> {
  setFetch: (payload: boolean) => Action<boolean>;
}

export const ACTIONS: ActionsModule = {
  SET_FETCH: '[load] SET fetch',
};

export const CREATORS: FetchCreators = {
  setFetch: creators.action<boolean>(ACTIONS.SET_FETCH),
};
