import React, { FC } from 'react';
import { TITLE } from '@constants';

import './Title.scss';
import { Button } from 'antd';
import { TitleAction } from '@types';

const Title: FC = () => {
  return (
    <section className='title'>
      <h1 className='title__text'>{TITLE.title}</h1>
      <div className='action__container'>
        {TITLE.actions.map(({ icon, title, className, click }: TitleAction) => (
          <Button key={title} className={`action ${className}`} onClick={click} icon={icon}>
            {title}
          </Button>
        ))}
      </div>
    </section>
  );
};

export default Title;
