import React, { FC } from 'react';
import { EllipsisOutlined } from '@ant-design/icons';
import { Menu, Dropdown } from 'antd';

import { TABLE_ACTIONS } from '@constants';

import './Cell.scss';

const ActionList = (
  <Menu>
    {TABLE_ACTIONS.map((title: string, index: number) => (
      <Menu.Item key={index}>
        <span className='cell__actions--name'>{title}</span>
      </Menu.Item>
    ))}
  </Menu>
);

const Actions: FC = () => {
  return (
    <Dropdown overlay={ActionList} trigger={['click']}>
      <EllipsisOutlined className='cell__actions' />
    </Dropdown>
  );
};

export default Actions;
