import { ActionsModule, ActionCreators, Action } from '@actions';
import { creators } from '@utils';

export type PaginationActionType =
  | '[pagination] SET current'
  | '[pagination] SET total'
  | '[pagination] SET pages'
  | '[pagination] SET size';

export type PaginationActionGeneric = number;

export interface PaginationCreators extends ActionCreators<PaginationActionGeneric> {
  setCurrent: (payload: number) => Action<number>;
  setTotal: (payload: number) => Action<number>;
  setPages: (payload: number) => Action<number>;
  setSize: (payload: number) => Action<number>;
}

export const ACTIONS: ActionsModule = {
  SET_CURRENT: '[pagination] SET current',
  SET_TOTAL: '[pagination] SET total',
  SET_PAGES: '[pagination] SET pages',
  SET_SIZE: '[pagination] SET size',
};

export const CREATORS: PaginationCreators = {
  setCurrent: creators.action<number>(ACTIONS.SET_CURRENT),
  setTotal: creators.action<number>(ACTIONS.SET_TOTAL),
  setPages: creators.action<number>(ACTIONS.SET_PAGES),
  setSize: creators.action<number>(ACTIONS.SET_SIZE),
};
