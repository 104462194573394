import { TEXT_TITLE } from '@constants';
import { Card, Filter, Search, Tab, CardType, Color, ColorActive } from '@types';

export const TAB: Tab = {
  title: '',
  type: 'all',
};

export const TABS: Tab[] = [
  {
    title: TEXT_TITLE.ALL_ARTWORKS,
    type: 'all',
  },
  {
    title: TEXT_TITLE.LICENCE,
    type: 'licence',
  },
  {
    title: TEXT_TITLE.SHELF,
    type: 'shelf',
  },
];

export const SEARCH: Search = {
  model: '',
  tags: [],
  label: TEXT_TITLE.SEARCH,
  placeholder: TEXT_TITLE.SEARCH_PLACEHOLDER,
  options: [],
  fixedOptions: [],
};

export const CARD: Card = {
  title: '',
  items: [],
  type: 'key',
  search: SEARCH,
};

export const FILTER: Filter = {
  cards: [],
  tab: 'all',
  search: SEARCH,
  tagsFilter: [],
};

export const HEADER_FITERS: Array<CardType> = ['shortcuts', 'tags', 'status', 'by'];

export const FILTERS: Array<CardType> = [
  'ID',
  'SKU',
  'by',
  'name',
  'settings',
  'mockup',
  'media',
  'specification',
  'colors',
  'marketing',
  'marketingLanguages',
  'products',
  'shortcuts',
  'tags',
  'status',
];

export const COLORS: Color[] = [
  Color.tagsLightGray,
  Color.tagsDarkGray,
  Color.tagsBrown,
  Color.tagsRed,
  Color.tagsOrange,
  Color.tagsYellow,
  Color.tagsPink,
  Color.tagsPurple,
  Color.tagsBlue,
  Color.tagsGreen,
];

export const COLORS_ACTIVE: Map<Color, ColorActive> = new Map<Color, ColorActive>([
  [Color.tagsLightGray, ColorActive.tagsLightGray],
  [Color.tagsDarkGray, ColorActive.tagsDarkGray],
  [Color.tagsBrown, ColorActive.tagsBrown],
  [Color.tagsRed, ColorActive.tagsRed],
  [Color.tagsOrange, ColorActive.tagsOrange],
  [Color.tagsYellow, ColorActive.tagsYellow],
  [Color.tagsPink, ColorActive.tagsPink],
  [Color.tagsPurple, ColorActive.tagsPurple],
  [Color.tagsBlue, ColorActive.tagsBlue],
  [Color.tagsGreen, ColorActive.tagsGreen],
]);
