import { Auth } from '@types';

export const TOKEN_ACCESS = 'TOKEN_ACCESS';
export const TOKEN_REFRESH = 'TOKEN_REFRESH';

export const AUTH: Auth = {
  user: {
    name: '',
  },
  AccessToken: '',
  RefreshToken: '',
};
