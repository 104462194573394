import { Action, Type } from '@actions';
import { Color, ProductListing } from '@types';
import { Table } from 'antd';
import { ColumnType } from 'antd/lib/table';
import React, { Key } from 'react';
import { COLORS, COLORS_ACTIVE } from '@constants';

const action =
  <T>(type: Type) =>
  (payload: T): Action<T> => ({
    type,
    payload,
  });

const selection = (selected: Key[], setSelected: (payload: React.Key[]) => Action<React.Key[]>) => {
  return {
    selectedRowKeys: selected,
    onChange: (selectedRowKeys: Key[]) => {
      setSelected(selectedRowKeys);
    },
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: 'odd',
        text: 'Select Odd Row',
        onSelect: (changableRowKeys: Key[]) => {
          setSelected(
            changableRowKeys.filter((key: Key, index: number) => {
              if (index % 2 !== 0) {
                return false;
              }
              return true;
            }),
          );
        },
      },
      {
        key: 'even',
        text: 'Select Even Row',
        onSelect: (changableRowKeys: Key[]) => {
          setSelected(
            changableRowKeys.filter((key: Key, index: number) => {
              if (index % 2 !== 0) {
                return true;
              }
              return false;
            }),
          );
        },
      },
    ],
  };
};

const paginationTitle = (page: number, size: number, total: number): string =>
  `Showing ${page * size - size + 1} - ${page * size > total ? total : page * size} of ${total} products`;

const pages = (size: number, count: number, total: number) => {
  const pages: string[] = [];
  let range = [1, size];
  for (let i = 1; i <= count; i += 1) {
    pages.push(`${i} (${range[0]} - ${range[1] > total ? total : range[1]})`);
    range = [range[1] + 1, range[1] + size];
  }

  return pages;
};

const column = (key: string, title: React.FC, render?: React.FC<any>, width = 100, className = 'column'): ColumnType<ProductListing> => ({
  title,
  dataIndex: key,
  key,
  className,
  width,
  render,
});

const color = (color = '', active: boolean): string => {
  if (!COLORS.includes(color as Color)) {
    color = COLORS[Math.floor(Math.random() * COLORS.length)];
  }

  if (active && COLORS_ACTIVE.has(color as Color)) {
    return COLORS_ACTIVE.get(color as Color) || '';
  }
  return color;
};

export { action, selection, paginationTitle, pages, column, color };
