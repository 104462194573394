import React, { FC, useEffect, useState, useLayoutEffect } from 'react';
import { Button, Tag } from 'antd';
import { connect } from 'react-redux';

import { FilterCreators, FILTER_CREATORS } from '@actions';
import { FILTER_STATE, TEXT_ACTION } from '@constants';
import { Card, CardProps, Filter, FilterOption } from '@types';
import { compose, creators } from '@utils';

const TagGroup: FC<CardProps & FilterCreators & Filter> = ({ type, cards, updateCard }) => {
  const [index, setIndex] = useState<number>(0);
  const [allSelected, setAllSelected] = useState<boolean | null>(null);

  useEffect(() => {
    setTimeout(() => {
      const index = cards.findIndex((card: Card) => card.type === type);

      if (index !== -1) {
        setIndex(index);
      }
    }, 0);
  }, [type]);

  useLayoutEffect(() => {
    if (allSelected !== null) {
      const tags = cards[index].items;
      tags.forEach((tag: FilterOption) => (tag[2] = Boolean(allSelected)));

      updateCard({
        ...cards[index],
        items: tags,
      });
    }
  }, [allSelected]);

  const setSelect = (i: number, status: boolean) => {
    const tag = cards[index].items[i];
    tag[2] = status;

    updateCard({
      ...cards[index],
      items: [...cards[index].items.slice(0, i), tag, ...cards[index].items.slice(i + 1)],
    });
  };

  const toggleSelectAll = () => {
    setAllSelected(current => !current);
  };

  return (
    <section className='card-tag'>
      <div className='card-tag__header'>
        <span className='card-tag__title'>{cards[index].title}</span>
        <Button className='card-tag__select-all' onClick={toggleSelectAll}>
          {allSelected ? TEXT_ACTION.CLEAR_ALL : TEXT_ACTION.SELECT_ALL}
        </Button>
      </div>
      <div className='card-tag__options'>
        {cards[index].items.map((tag: FilterOption, i: number) => (
          <Tag
            className='card-tag__option'
            color={creators.color(tag[3], tag[2])}
            key={tag[0]}
            visible
            closable={tag[2]}
            onClick={() => setSelect(i, true)}
            onClose={() => setSelect(i, false)}
          >
            {tag[0]}
          </Tag>
        ))}
      </div>
    </section>
  );
};

export default compose(connect(FILTER_STATE, FILTER_CREATORS))(TagGroup);
