import React, { FC } from 'react';
import { Checkbox as AntCheckbox, Dropdown, Tag, Menu } from 'antd';

import { CheckboxProps, FlagProps } from '@types';

import './Cell.scss';

const Checkbox: FC<CheckboxProps> = ({ disabled = true, checked = false, Icon, status = '', flags = [], type, statuses, sku }) => {
  const menu = (
    <Menu className='settings_items_menu'>
      License status
      <Menu.Item key='1'>
        <button
          className={`statuses`}
          onClick={e => {
            console.log(e.currentTarget.textContent, sku);
          }}
        >
          Rejected
        </button>
      </Menu.Item>
      <Menu.Item key='2'>
        <button
          className={`statuses`}
          onClick={e => {
            console.log(e.currentTarget.textContent, sku);
          }}
        >
          Pending
        </button>
      </Menu.Item>
      <Menu.Item key='3'>
        <button
          className={`statuses`}
          onClick={e => {
            console.log(e.currentTarget.textContent, sku);
          }}
        >
          Aproved
        </button>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      {type === 'settings' ? (
        <Dropdown overlay={menu} placement='bottomRight' trigger={['click']} getPopupContainer={trigger => trigger.parentNode as any}>
          <div className='settings_select'>
            {!!Icon && <Icon className={checked ? 'cell__checkbox--active' : '' || disabled ? 'cell__checkbox--disabled' : ''} />}
            <div>
              <button className={`${statuses?.settings}`}>{statuses?.settings}</button>
            </div>
          </div>
        </Dropdown>
      ) : (
        <AntCheckbox className='cell__checkbox' disabled={disabled}>
          <Icon className={checked ? 'cell__checkbox--active' : '' || disabled ? 'cell__checkbox--disabled' : ''} />
          {checked && !!status && <Tag className={status}>{status}</Tag>}
          {!!flags.length && <Flags flags={flags} />}
        </AntCheckbox>
      )}
    </>
  );
};

const Flags: FC<FlagProps> = ({ flags }) => (
  <div className='flag-icon--container'>
    {flags.map((flag: string) => (
      <i key={flag} className={`flag-icon--rectangle flag-icon-country-${flag.toLowerCase()}`}></i>
    ))}
  </div>
);

export default Checkbox;
