import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './index.scss';
import './variables.scss';
import './flags.scss';

import 'antd/dist/antd.css';

import App from '@app';
import { store } from '@redux';
import { ErrorBoundary } from '@containers';
import { BrowserRouter } from 'react-router-dom';
const root = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <ErrorBoundary>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ErrorBoundary>
  </Provider>,
  root,
);
