import React, { FC, useEffect, useState } from 'react';
import { Checkbox, Select, Tag } from 'antd';

import { ApiProps, Card, CardProps, Filter, FilterOption, State } from '@types';
import { FETCH_STATE, FILTER_STATE, PAGINATION_STATE, SORT_STATE, TABLE_STATE, TEXT_TITLE } from '@constants';
import { compose, creators } from '@utils';
import { connect } from 'react-redux';
import {
  FetchCreators,
  FETCH_CREATORS,
  FilterCreators,
  FILTER_CREATORS,
  PaginationCreators,
  PAGINATION_CREATORS,
  SortCreators,
  SORT_CREATORS,
  TableCreators,
  TABLE_CREATORS,
} from '@actions';
import { Search } from '@icons';
import { PlusOutlined } from '@ant-design/icons';
import { withApiConsumer } from '@containers';

const CheckboxSelect: FC<
  CardProps &
    FilterCreators &
    Filter &
    State &
    ApiProps &
    TableCreators &
    PaginationCreators &
    FilterCreators &
    SortCreators &
    FetchCreators
> = ({
  type,
  cards,
  visible = true,
  showPlaceholder = true,
  updateCard,
  selected: groupSelected,
  api,
  setName,
  setCards,
  setRows,
  setPages,
  setCurrent,
  setSize,
  setTotal,
  setTab,
  setSearchOptions,
  setSearchFixedOptions,
  tab,
  setFetch,
  isFetching,
}) => {
  const [index, setIndex] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(visible);
  const [selected, setSelected] = useState<string[]>([]);

  useEffect(() => {
    const index = cards.findIndex((card: Card) => card.type === type);

    if (index !== -1) {
      setIndex(index);
      setSelected(cards[index].items.filter((item: FilterOption) => !!item[2]).map((item: FilterOption) => item[0]));
    }
  }, [type]);

  useEffect(() => {
    setSelected(cards[index].items.filter((item: FilterOption) => !!item[2]).map((item: FilterOption) => item[0]));
  }, [groupSelected]);

  const toggle = (itemIndex: number, current: boolean) => {
    if (!current && cards[index]) {
      const tag = cards[index].items[itemIndex];
      tag[2] = !tag[2];

      updateCard({
        ...cards[index],
        items: [...cards[index].items.slice(0, itemIndex), tag, ...cards[index].items.slice(itemIndex + 1)],
      });
    }
  };

  const onDeselect = (title: string) => {
    const itemIndex = cards[index].items.findIndex((item: FilterOption) => item[0] === title);
    if (itemIndex !== -1) {
      const tag = cards[index].items[itemIndex];
      tag[2] = false;

      updateCard({
        ...cards[index],
        items: [...cards[index].items.slice(0, itemIndex), tag, ...cards[index].items.slice(itemIndex + 1)],
      });
    }
  };

  const getSelect = (value: string) => {
    setOpen(true);
    console.log(value);
    api.searchAutocomplete(type, 1, [value]).then(() => {
      console.log(1);
    });
  };

  return (
    <section className='card-select'>
      <Select
        dropdownClassName='card-select__dropdown'
        open={open}
        maxTagCount='responsive'
        mode='tags'
        tagRender={tags}
        value={selected}
        placeholder={
          <div className='card-select__placeholder'>
            {showPlaceholder && cards[index].title}
            <Tag className='card-select__placeholder-tag'>
              {TEXT_TITLE.SEARCH_CARD_PLACEHOLDER}
              <PlusOutlined />
            </Tag>
          </div>
        }
        onFocus={() => setOpen(true)}
        onSelect={getSelect}
        onBlur={() => setOpen(false)}
        onDeselect={onDeselect}
      >
        {cards[index].items.map((item: FilterOption, i) => (
          <Select.Option value={item[0]} key={i}>
            <Checkbox className='card-select__checkbox' checked={item[2]} onChange={() => toggle(i, item[2])}>
              <span className='card-select__option-title'>{item[0]}</span>
            </Checkbox>
          </Select.Option>
        ))}
      </Select>
      <Search className='card-select__icon' />
    </section>
  );
};

function tags(props: any) {
  const { label, closable, onClose } = props;

  const onPreventMouseDown = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      color={creators.color('', false)}
      className='card-select__selected'
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
    >
      {label}
    </Tag>
  );
}

export default compose(
  connect(TABLE_STATE, TABLE_CREATORS),
  connect(PAGINATION_STATE, PAGINATION_CREATORS),
  connect(FILTER_STATE, FILTER_CREATORS),
  connect(SORT_STATE, SORT_CREATORS),
  connect(FETCH_STATE, FETCH_CREATORS),
  withApiConsumer(),
)(CheckboxSelect);
