import { ActionsModule, Action, ActionCreators } from '@actions';
import { creators } from '@utils';

export type AuthActionType = '[auth] SET user name' | '[auth] SET token access' | '[auth] SET token refresh';

export type AuthActionGeneric = string;

export interface AuthCreators extends ActionCreators<string> {
  setTokenAccess: (payload: string) => Action<string>;
  setTokenRefresh: (payload: string) => Action<string>;
  setUserName: (payload: string) => Action<string>;
}

export const ACTIONS: ActionsModule = {
  SET_TOKEN_ACCESS: '[auth] SET token access',
  SET_TOKEN_REFRESH: '[auth] SET token refresh',
  SET_USER_NAME: '[auth] SET user name',
};

export const CREATORS: AuthCreators = {
  setTokenAccess: creators.action<string>(ACTIONS.SET_TOKEN_ACCESS),
  setTokenRefresh: creators.action<string>(ACTIONS.SET_TOKEN_REFRESH),
  setUserName: creators.action<string>(ACTIONS.SET_USER_NAME),
};
