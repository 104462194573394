import { ActionsModule, Action, ActionCreators } from '@actions';
import { Order } from '@types';
import { creators } from '@utils';

export type SortActionType = '[sort] SET last action order';

export type SortActionGeneric = Order;

export interface SortCreators extends ActionCreators<Order> {
  setLastActionOrder: (payload: Order) => Action<Order>;
}

export const ACTIONS: ActionsModule = {
  SET_LAST_ACTION_ORDER: '[sort] SET last action order',
};

export const CREATORS: SortCreators = {
  setLastActionOrder: creators.action<Order>(ACTIONS.SET_LAST_ACTION_ORDER),
};
